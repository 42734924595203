import { useCallback } from 'react';
import { useAtomCallback } from 'jotai/utils';
import { IGridEvent } from 'types/events';
import { useSetEventsSelection } from './useEventsSelection';
import { visibleEventsAtom } from './events/eventAtoms';

export function useSelectEventsInRange() {
  const { setEventsSelection } = useSetEventsSelection();
  const selectEventsInRange = useAtomCallback(
    useCallback(
      (get, _set, selectedEventId: string) => {
        const events = get(visibleEventsAtom);
        setEventsSelection((prevSelection) =>
          _computeSelection(events, prevSelection, selectedEventId)
        );
      },
      [setEventsSelection]
    )
  );
  return selectEventsInRange;
}

export function _computeSelection(
  events: Array<Pick<IGridEvent, 'id' | 'startAt'>>,
  currentSelection: string[],
  selectedEventId: string
): string[] {
  if (currentSelection.length === 0) {
    return [selectedEventId];
  }

  const selectedEvent = events.find((event) => event.id === selectedEventId);
  if (!selectedEvent) {
    // Error event not found
    return currentSelection;
  }

  const eventSelection = currentSelection
    .map((eventId) => events.find((event) => event.id === eventId))
    .filter(isNotEmpty)
    .concat([selectedEvent])
    .sort((eventA, eventB) => (eventA.startAt > eventB.startAt ? 1 : -1));

  const firstEvent = eventSelection[0];
  const lastEvent = eventSelection[eventSelection.length - 1];

  if (firstEvent.id === selectedEventId || lastEvent.id === selectedEventId) {
    return events
      .filter(
        (event) =>
          event.startAt >= firstEvent.startAt &&
          event.startAt <= lastEvent.startAt
      )
      .map((event) => event.id);
  }

  // start from selectedElement
  return events
    .filter(
      (event) =>
        event.startAt >= selectedEvent.startAt &&
        event.startAt <= lastEvent.startAt
    )
    .map((event) => event.id);
}

function isNotEmpty<T>(value?: T): value is T {
  return !!value;
}
