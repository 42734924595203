import React from 'react';
import { IGridDay } from '../../types/events';
import GridDay from './GridDay';
import GridTimezones from './GridTimezones';

interface Props {
  days: IGridDay[];
  numberOfTimezones: number;
}

export default React.memo(function GridTitles({
  days,
  numberOfTimezones,
}: Props): JSX.Element {
  return (
    <div
      className={`w-100 bg-background border-separator sticky top-0 z-80 grid h-11 border-b grid-cols-calendar${numberOfTimezones}Timezones`}
    >
      <GridTimezones />
      {days.map(({ index, date }) => (
        <GridDay key={`title-${index}`} index={index} date={date} />
      ))}
    </div>
  );
});
