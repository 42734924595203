import { useSpotifyPauseSongMutation } from 'graphql/mutations/SpotifyPauseSong.graphql';
import { useSpotifyPlaySongMutation } from 'graphql/mutations/SpotifyPlaySong.graphql';
import { useSpotifyCurrentlyPlayingQuery } from 'graphql/queries/spotifyCurrentlyPlayingTrack.graphql';
import { useMemo } from 'react';
import { useIsGridEventsReady } from './events/useGridEvents';
import { useSpotifyRecentlyPlayedQuery } from 'graphql/queries/spotifyRecentlyPlayed.graphql';
import { useUserQuery } from 'graphql/queries/user.graphql';
import useCalendar from './useCalendar';
import { isFuture } from 'utils/time';
import { DateTime, Interval } from 'luxon';

function useSpotify(email?: string) {
  const eventsReady = useIsGridEventsReady();
  const { startDate, endDate } = useCalendar();
  const [, playSongMutation] = useSpotifyPlaySongMutation();
  const [, pauseSongMutation] = useSpotifyPauseSongMutation();
  const isInFuture = useMemo(() => isFuture(startDate), [startDate]);

  const [userQueryRes] = useUserQuery({
    pause: !eventsReady,
    requestPolicy: 'cache-only',
  });
  const [queryResCurrent] = useSpotifyCurrentlyPlayingQuery({
    pause: Boolean(email) === false,
    requestPolicy: 'cache-and-network',
    variables: {
      targetUser: useMemo(() => email || '', [email]),
    },
  });
  const [queryResRecent] = useSpotifyRecentlyPlayedQuery({
    variables: {
      date: useMemo(() => endDate, [endDate]),
    },
    pause:
      isInFuture ||
      !eventsReady ||
      !userQueryRes.data?.new_user[0].spotifyRefreshToken,
  });

  const filteredByCalendarRange = useMemo(() => {
    if (isInFuture) return [];
    if (!queryResRecent.data?.spotifyRecentlyPlayed?.tracks) return [];

    return queryResRecent.data.spotifyRecentlyPlayed.tracks.filter((track) =>
      Interval.fromDateTimes(startDate, endDate).contains(
        DateTime.fromJSDate(new Date(track.playedAt))
      )
    );
  }, [queryResRecent.data, startDate, endDate, isInFuture]);

  return {
    currentlyPlaying: queryResCurrent.data?.spotifyCurrentlyPlaying,
    recentlyPlayed: filteredByCalendarRange,
    playSong: playSongMutation,
    pauseSong: pauseSongMutation,
  };
}

export default useSpotify;
