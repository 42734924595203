import useHotkey from './useHotkey';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  useEventsSelection,
  useSetEventsSelection,
} from './useEventsSelection';
import { IGridEvent } from 'types/events';
import { useVisibleEvents } from './events/useGridEvents';
import { useUpdateCalendar } from 'hooks/useCalendar';
import { closestIndexTo, isFuture, isPast } from 'utils/time';
import { useCalendarModeValue } from './useCalendarMode';
import { atom, useAtom } from 'jotai';
import { useUpdateModal } from './useModal';

enum ArrowDirection {
  Up,
  Right,
  Down,
  Left,
}

enum EventsLoadTrigger {
  NONE,
  Earliest,
  Latest,
  First,
  Last,
}

export const isNavigatingWithKeyboardAtom = atom(false);

export default function useEventNavigation(): void {
  const calendarMode = useCalendarModeValue();
  const { goToNextWeek, goToPrevWeek } = useUpdateCalendar();
  const eventsLoadTrigger = useRef<EventsLoadTrigger | null>(null);
  const events = useVisibleEvents();
  const eventsSelection = useEventsSelection();
  const { selectEvent, clearEventsSelection } = useSetEventsSelection();
  const selectedEvent = events.find((event) => event.id === eventsSelection[0]);
  const [isNavigatingWithKeyboard, setNavigatingWithKeyboard] = useAtom(
    isNavigatingWithKeyboardAtom
  );
  const { closeModal } = useUpdateModal();

  const sortedEvents = useMemo(() => {
    return events
      .sort((a, b) => {
        return a.startAt.toJSDate().getTime() - b.startAt.toJSDate().getTime();
      })
      .filter((e) => !e.isAllDay);
  }, [events]);

  useEffect(() => {
    const dayIndex = events.findIndex((event) =>
      eventsSelection.find((selectedEventId) => selectedEventId === event.id)
    );
    if (eventsLoadTrigger.current === null || dayIndex >= 0) return;
    let eventToFocus: IGridEvent | undefined;

    switch (eventsLoadTrigger.current) {
      case EventsLoadTrigger.First:
        eventToFocus = getFirstEventOfWeek(events);
        break;
      case EventsLoadTrigger.Last:
        eventToFocus = getLastEventOfWeek(events);
        break;
      case EventsLoadTrigger.Earliest:
        eventToFocus = getEarliestEventOfWeek(events);
        break;
      case EventsLoadTrigger.Latest:
        eventToFocus = getLatestEventOfWeek(events);
        break;
      case EventsLoadTrigger.NONE:
        eventToFocus = undefined;
        break;
    }

    eventsLoadTrigger.current = null;
    if (eventToFocus?.id) {
      selectEvent(eventToFocus?.id, true);
    } else {
      clearEventsSelection();
    }
  }, [clearEventsSelection, events, eventsSelection, selectEvent]);

  const navigateWeek = useCallback(
    (direction: 'previous' | 'next', trigger: EventsLoadTrigger) => {
      eventsLoadTrigger.current = trigger;
      return direction === 'next' ? goToNextWeek() : goToPrevWeek();
    },
    [goToPrevWeek, goToNextWeek]
  );

  const findEvent = useCallback(
    (direction: ArrowDirection) => {
      if (!selectedEvent) return;

      const eventIndex = sortedEvents.findIndex(
        (event) => event.id === selectedEvent.id
      );

      switch (direction) {
        case ArrowDirection.Up: {
          // Events are already sorted, so go to the prev one, else navigate a week backward
          const prevEvent = sortedEvents[eventIndex - 1];
          if (prevEvent) {
            selectEvent(prevEvent.id, true);
          } else {
            navigateWeek('previous', EventsLoadTrigger.Last);
          }
          break;
        }
        case ArrowDirection.Down: {
          // Events are already sorted, so go to the next one, else navigate a week forward
          const nextEvent = sortedEvents[eventIndex + 1];
          if (nextEvent) {
            selectEvent(nextEvent.id, true);
          } else {
            navigateWeek('next', EventsLoadTrigger.First);
          }
          break;
        }
        case ArrowDirection.Right: {
          // Move to an event on the next available day with the closest time slot
          const futureEvent = getFutureClosestEvent(
            selectedEvent,
            sortedEvents
          );

          if (futureEvent) {
            selectEvent(futureEvent.id, true);
            return;
          }

          // Move to the next week if no future event exists in the week
          navigateWeek('next', EventsLoadTrigger.First);
          break;
        }
        case ArrowDirection.Left: {
          // Move to an event on a previously available day with the closest time slot
          const pastEvent = getPastClosestEvent(selectedEvent, sortedEvents);
          if (pastEvent) {
            selectEvent(pastEvent.id, true);
            return;
          }

          // Move to the previous week if no previous event exists in the week
          navigateWeek('previous', EventsLoadTrigger.Last);
          break;
        }
      }
    },
    [sortedEvents, navigateWeek, selectEvent, selectedEvent]
  );

  const handleUp = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      if (!isNavigatingWithKeyboard) setNavigatingWithKeyboard(true);
      closeModal();

      // If we have something focused already, find the next one up
      if (selectedEvent) {
        findEvent(ArrowDirection.Up);
        return;
      }

      // Find the closest event near the current time
      const closestEvent = getPrevEventToday(events);
      if (closestEvent) {
        selectEvent(closestEvent.id, true);
        return;
      }

      // If no close event is found, go to the latest event of the week
      const latestEvent = getLatestEventOfWeek(events);
      if (!latestEvent) return;
      selectEvent(latestEvent.id, true);
    },
    [
      selectedEvent,
      events,
      selectEvent,
      findEvent,
      setNavigatingWithKeyboard,
      isNavigatingWithKeyboard,
      closeModal,
    ]
  );

  const handleDown = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();

      if (!isNavigatingWithKeyboard) setNavigatingWithKeyboard(true);
      closeModal();

      // If we have something focused already, find the next one down
      if (selectedEvent) {
        findEvent(ArrowDirection.Down);
        return;
      }

      // Find the closest event near the current time
      const closestEvent = getNextEventToday(events);
      if (closestEvent) {
        selectEvent(closestEvent.id, true);
        return;
      }

      // If no close event is found, go to the earliest event of the week
      const earliestEvent = getEarliestEventOfWeek(events);
      if (!earliestEvent) return;
      selectEvent(earliestEvent.id, true);
    },
    [
      selectedEvent,
      events,
      selectEvent,
      findEvent,
      setNavigatingWithKeyboard,
      isNavigatingWithKeyboard,
      closeModal,
    ]
  );

  const handleRight = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      if (!isNavigatingWithKeyboard) setNavigatingWithKeyboard(true);
      closeModal();
      // If we have something focused already, find the next to the right
      if (eventsSelection.length > 0) {
        findEvent(ArrowDirection.Right);
        return;
      }

      // Navigate to next week if no event is focused
      navigateWeek('next', EventsLoadTrigger.NONE);
    },
    [
      findEvent,
      eventsSelection,
      navigateWeek,
      setNavigatingWithKeyboard,
      isNavigatingWithKeyboard,
      closeModal,
    ]
  );

  const handleLeft = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      if (!isNavigatingWithKeyboard) setNavigatingWithKeyboard(true);
      closeModal();

      // If we have something focused already, find the next to the left
      if (eventsSelection.length > 0) {
        findEvent(ArrowDirection.Left);
        return;
      }

      // Navigate to previous week if no event is focused
      navigateWeek('previous', EventsLoadTrigger.NONE);
    },
    [
      findEvent,
      eventsSelection,
      navigateWeek,
      setNavigatingWithKeyboard,
      isNavigatingWithKeyboard,
      closeModal,
    ]
  );

  const handleX = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      if (!isNavigatingWithKeyboard) {
        setNavigatingWithKeyboard(true);
      }
      closeModal();

      const ongoingOrNextEvent =
        getOngoingEvent(events) || getNextEventToday(events);
      if (ongoingOrNextEvent) {
        if (selectedEvent?.id === ongoingOrNextEvent?.id) {
          return void clearEventsSelection();
        }

        return void selectEvent(ongoingOrNextEvent.id, true);
      }

      navigateWeek('next', EventsLoadTrigger.First);
    },
    [
      selectedEvent,
      events,
      selectEvent,
      clearEventsSelection,
      navigateWeek,
      setNavigatingWithKeyboard,
      isNavigatingWithKeyboard,
      closeModal,
    ]
  );

  useHotkey(
    'escape',
    { scope: 'global', enabled: calendarMode === 'default' },
    clearEventsSelection
  );
  useHotkey(
    'x',
    { scope: 'global', enabled: calendarMode === 'default' },
    handleX,
    [handleX]
  );
  useHotkey(
    'up',
    { scope: 'global', enabled: calendarMode === 'default' },
    handleUp,
    [handleUp]
  );

  useHotkey(
    'up',
    { scope: 'modal', enabled: calendarMode === 'default' },
    handleUp,
    [handleUp]
  );

  useHotkey(
    'right, j',
    { scope: 'global', enabled: calendarMode === 'default' },
    handleRight,
    [handleRight]
  );
  useHotkey(
    'left, k',
    { scope: 'global', enabled: calendarMode === 'default' },
    handleLeft,
    [handleLeft]
  );

  useHotkey(
    'down',
    { scope: 'global', enabled: calendarMode === 'default' },
    handleDown,
    [handleDown]
  );

  useHotkey(
    'down',
    { scope: 'modal', enabled: calendarMode === 'default' },
    handleDown,
    [handleDown]
  );
}

const extractTimeValue = (event: IGridEvent): number =>
  parseInt(`${event.startAt.toFormat('HH')}${event.startAt.toFormat('mm')}`);

const getOngoingEvent = (events: IGridEvent[]): IGridEvent | undefined => {
  const ongoingEvents = events.filter(
    (event) => isPast(event.startAt) && isFuture(event.endAt)
  );
  return ongoingEvents[0];
};

const getEarliestEventOfWeek = (
  events: IGridEvent[]
): IGridEvent | undefined => {
  return events.reduce((earliestEvent, event) => {
    if (extractTimeValue(event) < extractTimeValue(earliestEvent)) return event;
    return earliestEvent;
  }, events[0]);
};

const getLatestEventOfWeek = (events: IGridEvent[]): IGridEvent | undefined => {
  return events.reduce((latest, event) => {
    if (extractTimeValue(event) > extractTimeValue(event)) return event;
    return latest;
  }, events[0]);
};

const getFirstEventOfWeek = (events: IGridEvent[]): IGridEvent | undefined => {
  return events[0];
};

const getLastEventOfWeek = (events: IGridEvent[]): IGridEvent | undefined => {
  return events[events.length - 1];
};

const getPrevEventToday = (events: IGridEvent[]): IGridEvent | undefined => {
  const pastEvents = events.filter((event) => isPast(event.startAt));
  return pastEvents[pastEvents.length - 1];
};

const getNextEventToday = (events: IGridEvent[]): IGridEvent | undefined => {
  const futureEvents = events.filter((event) => isFuture(event.startAt));
  return futureEvents[0];
};

const getClosestEvent = (nextDate: DateTime, futureEvents: IGridEvent[]) =>
  futureEvents.reduce((a, b) => {
    const bDiff = b.startAt.diff(nextDate);
    const aDiff = a.startAt.diff(nextDate);

    return Math.abs(Number(bDiff)) < Math.abs(Number(aDiff)) ? b : a;
  });

const getPastClosestEvent = (
  selectedEvent: IGridEvent,
  events: IGridEvent[]
): IGridEvent | undefined => {
  const prevDate = selectedEvent.startAt.minus({ days: 1 });
  const pastEvents = [...events]
    .reverse()
    .filter((event) => event.startAt < prevDate.endOf('day'));

  if (pastEvents.length === 0) return undefined;
  const closestEvent = getClosestEvent(prevDate, pastEvents);
  return closestEvent;
};

const getFutureClosestEvent = (
  selectedEvent: IGridEvent,
  events: IGridEvent[]
): IGridEvent | undefined => {
  const nextDate = selectedEvent.startAt.plus({ days: 1 });
  const futureEvents = events.filter(
    (event) => event.startAt > nextDate.startOf('day')
  );

  if (futureEvents.length === 0) return undefined;

  const closestEvent = getClosestEvent(nextDate, futureEvents);

  return closestEvent;
};
