import { useDraggable } from '@dnd-kit/core';
import { CalendarSlotDate } from 'hooks/calendarLink/booking/atoms';
import React, { useCallback, useMemo } from 'react';
import { DraggableType } from 'types/drag-and-drop';
import { IGridDay } from '../../types/events';
import GridSlot from './GridSlot';
import { getDayIndex, getSlotStyle, Z_INDEX_SLOTS } from './utils';
import Tooltip from 'joy/Tooltip';
import { useUi24HourClock } from 'hooks/usePreferences';
import { useCalendarModeValue } from 'hooks/useCalendarMode';

interface Props {
  slot: CalendarSlotDate;
  timezone: string;
  days: IGridDay[];
  tooltip?: string;
  disabled?: boolean;
  type: DraggableType;
}

export default React.memo(function GridSlotWrapper({
  days,
  disabled,
  slot,
  timezone,
  type,
  tooltip,
}: Props) {
  const ui24HourClock = useUi24HourClock();
  const calendarMode = useCalendarModeValue();
  const { setNodeRef, listeners, attributes } = useDraggable({
    id: slot.id,
    data: { type, slot },
    disabled: disabled || calendarMode === 'default',
  });

  const setElementRef = useCallback(
    (element: HTMLDivElement | null) => {
      setNodeRef(element);
    },
    [setNodeRef]
  );

  const dayIndex =
    slot.recurrenceRules?.length > 0
      ? slot.startAt.setZone(timezone).weekday
      : getDayIndex(days, slot);

  const style = useMemo(() => {
    return {
      ...getSlotStyle({ slot, dayIndex, timezone }),
      zIndex: Z_INDEX_SLOTS,
    };
  }, [dayIndex, slot, timezone]);

  if (dayIndex < 1) return null;

  return (
    <Tooltip
      content={tooltip || 'Slot'}
      disabled={!tooltip}
      placement="top-center"
    >
      <GridSlot
        ref={setElementRef}
        calendarMode={calendarMode}
        slot={slot}
        timezone={timezone}
        style={style}
        disabled={disabled}
        ui24HourClock={ui24HourClock}
        {...listeners}
        {...attributes}
      />
    </Tooltip>
  );
});
