import React, { useMemo } from 'react';
import Button from 'joy/Button';
import IconSushiRoll from 'components/Icons/IconSushiRoll';
import IconTrash from 'components/Icons/IconTrash';
import IconDescription from 'components/Icons/IconDescription';
import Dropdown, { DropdownItem } from 'joy/Dropdown';
import { ARIA_LABEL_EVENT_POPOVER_OPTIONS } from 'utils/constants';
import { IGridEvent } from 'types/events';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import Tooltip from 'joy/Tooltip';
import { isDraftEvent } from 'components/Grid/utils';

interface Props {
  event: IGridEvent | undefined;
  visibleDescription: boolean;
  discardDraftEvent?: () => void;
  setVisibleDescription: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EventOptions({
  discardDraftEvent,
  event,
  visibleDescription,
  setVisibleDescription,
}: Props): JSX.Element {
  const { deleteDraftEvent, deleteGridEvent } = useUpdateGridEvent();

  const menuItems: DropdownItem[] = useMemo(() => {
    const items: DropdownItem[] = [];

    if (!visibleDescription && (event?.isOwnEvent || !event)) {
      items.push({
        type: 'option',
        value: 'Add description',
        onSelect: () => setVisibleDescription(true),
        icon: <IconDescription className="h-4 w-4" />,
      });
    }

    if (event) {
      const eventId = event.id;

      if (eventId && event.isOwnEvent) {
        items.push({
          type: 'option',
          value: 'Delete event',
          variant: 'red',
          onSelect: async () => {
            if (!event) return;

            if (isDraftEvent(event)) {
              deleteDraftEvent();
            } else {
              deleteGridEvent({
                eventId,
                calendarId: event.calendarId,
              });
            }
          },
          icon: <IconTrash className="h-4 w-4" />,
        });
      }
    }

    if (typeof event === 'undefined' && discardDraftEvent) {
      items.push({
        type: 'option',
        value: 'Discard event',
        onSelect: discardDraftEvent,
        icon: <IconTrash className="h-4 w-4" />,
      });
    }

    return items;
  }, [
    visibleDescription,
    event,
    deleteDraftEvent,
    discardDraftEvent,
    setVisibleDescription,
    deleteGridEvent,
  ]);

  return (
    <Dropdown items={menuItems}>
      <Tooltip content="Options">
        <Button
          className="rotate rotate-90"
          variant="popoverIcon"
          ariaLabel={ARIA_LABEL_EVENT_POPOVER_OPTIONS}
        >
          <IconSushiRoll className="h-4 w-4" />
        </Button>
      </Tooltip>
    </Dropdown>
  );
}
