import classNames from 'classnames';
import { useFavoriteContact } from 'hooks/useFavoriteContact';
import useHotkey from 'hooks/useHotkey';
import Button from 'joy/Button';
import React, { useEffect, useMemo } from 'react';

type Props = {
  onSelect: (isFavorite: boolean) => void;
  contactId: string;
  children: React.ReactNode;
  disabled?: boolean;
  isKeyboardFocused?: boolean;
};

export default React.memo(function ColleagueButton({
  onSelect,
  contactId,
  children,
  isKeyboardFocused,
  disabled,
  ...props
}: Props): JSX.Element {
  const { isFavorite, toggle } = useFavoriteContact(contactId);

  useEffect(() => {
    if (onSelect && isFavorite) onSelect(isFavorite);
  }, [isFavorite, onSelect]);

  useHotkey(
    'enter',
    {
      enabled: isKeyboardFocused && !disabled,
      scope: 'onboarding',
    },
    () => {
      toggle(isFavorite);
    },
    [isKeyboardFocused]
  );

  return useMemo(
    () => (
      <Button
        {...props}
        onClick={() => toggle(isFavorite)}
        className={classNames(
          'm-1.5 flex cursor-pointer items-center rounded-md p-3 font-semibold',
          {
            'bg-gray-600 text-gray-100 dark:bg-gray-200 dark:text-gray-900':
              isFavorite,
            'bg-gray-100 hover:bg-gray-200/60 dark:bg-gray-700/60 dark:text-gray-100 dark:hover:bg-gray-700':
              !isFavorite,
            'scale-102': isKeyboardFocused,
          }
        )}
      >
        {children}
      </Button>
    ),
    [props, isFavorite, toggle, children, isKeyboardFocused]
  );
});
