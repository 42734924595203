import { ColorFamily } from '@graphql-types@';
import cn from 'classnames';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import React, { useCallback, useRef } from 'react';
import { IGridEvent } from 'types/events';
import { EVENT_COLOR_MAP } from 'utils/eventColors';

interface Props {
  isAllDay: boolean;
  onChange: (value: IGridEvent['isAllDay']) => void;
  colorFamily: ColorFamily;
  readOnly: boolean;
}

export default function EventAllDayToggle({
  isAllDay,
  onChange,
  colorFamily,
  readOnly,
}: Props) {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const tooltipText = readOnly
    ? isAllDay
      ? 'All day'
      : 'Not all day'
    : isAllDay
    ? 'Remove All day'
    : 'Set as All day';

  const onClick = useCallback(() => {
    buttonRef.current?.blur();
    onChange(!isAllDay);
  }, [isAllDay, onChange]);

  const colorItem = EVENT_COLOR_MAP[colorFamily];
  return (
    <Tooltip content={tooltipText}>
      <Button
        variant="popoverIcon"
        onClick={onClick}
        ref={buttonRef}
        style={{ width: 'auto' }}
        className={cn('text-xs', {
          [`${colorItem.buttonAllDayEnabled} ${colorItem.text}`]: isAllDay,
          ['cursor-default']: readOnly,
        })}
        disabled={readOnly}
      >
        24h
      </Button>
    </Tooltip>
  );
}
