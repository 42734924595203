import classNames from 'classnames';
import {
  useAllDayEventsIds,
  useAllDayRows,
} from 'hooks/events/useAllDayEvents';
import { useCalendarDays } from 'hooks/useCalendar';
import React, { useEffect, useRef, useState } from 'react';
import { DraggableType } from 'types/drag-and-drop';
import { TEST_ID_ALLDAY_GRID } from 'utils/constants';
import GridAllDayInteractionHandler from './clickHandler/GridAllDayInteractionsHandler';
import GridEventWrapper from './GridEventWrapper';
import { gridPosition } from './utils';

const MAX_ROWS = 4;

interface Props {
  numberOfTimezones: number;
}

export default function GridAllDay({ numberOfTimezones }: Props) {
  const eventsIds = useAllDayEventsIds();
  const ref = useRef<HTMLDivElement | null>(null);
  const rows = useAllDayRows();
  const days = useCalendarDays();

  const height = `${rows * 1.5}em`;
  const hasMaxRows = rows >= MAX_ROWS;
  const [isScrollTop, setScrollTop] = useState(true);

  useEffect(() => {
    const gridAllDayContent = ref.current;
    if (!gridAllDayContent) return;

    const handleScrolling = () => {
      setScrollTop(gridAllDayContent.scrollTop < 5);
    };

    if (gridAllDayContent) {
      gridAllDayContent.addEventListener('scroll', handleScrolling);
    }

    return () => {
      gridAllDayContent.removeEventListener('scroll', handleScrolling);
    };
  }, [isScrollTop, ref]);
  return (
    <div
      className="bg-background sticky top-11 z-80 w-full select-none"
      style={{ maxHeight: '4.5em' }}
    >
      <div className="border-separator absolute -bottom-px z-30 w-full border-b" />

      <div
        ref={ref}
        role="presentation"
        data-testid={TEST_ID_ALLDAY_GRID}
        className={classNames(
          `grid w-full grid-cols-calendar${numberOfTimezones}Timezones`,
          {
            'overflow-y-auto': hasMaxRows,
          }
        )}
        style={{
          maxHeight: '4.5em',
          height,
          gridTemplateRows: `repeat(${rows}, 1.5em)`,
        }}
      >
        <div
          className="pointer-events-none flex h-6 w-full select-none items-center justify-end text-right"
          style={{
            gridRow: '1',
            gridColumn: 0,
          }}
        >
          <small className="text-right text-xs font-medium text-gray-400 dark:text-gray-500">
            All day
          </small>
        </div>

        <GridAllDayInteractionHandler />
        {days.map((_, index) => (
          <span
            key={`allday-col-${index + 1}`}
            className="border-separator pointer-events-none border-l"
            style={{
              gridRow: '1 / -1',
              gridColumn: index + 1 + gridPosition.columnStart,
            }}
          />
        ))}
        {eventsIds.map((eventId, index) => (
          <GridEventWrapper
            key={`${index}-${eventId}`}
            allDay={true}
            days={days}
            eventId={eventId}
            type={DraggableType.NONE}
          />
        ))}
      </div>
    </div>
  );
}
