import { Theme_Enum as Theme } from '@graphql-types@';
import classNames from 'classnames';
import { usePreferences } from 'hooks/usePreferences';
import Avatar from 'joy/Avatar';
import { Loader } from 'joy/Loader';
import React from 'react';

interface Props {
  location: string | null;
  displayName?: string | null;
  avatar?: string | null;
}

export default function WidgetMap({ location, displayName, avatar }: Props) {
  const preferences = usePreferences();
  const hasLocation = location != null;
  const mapLocation = location || 'New York Stock Exchange';

  const origin =
    window.location.host.split(':')[0] === 'localhost'
      ? 'https://amie-staging.vercel.app'
      : '';
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('location', mapLocation);
  urlSearchParams.set(
    'mode',
    preferences.theme === Theme.Dark ? 'dark' : 'light'
  );

  return (
    <div className="relative flex flex-col items-center justify-center w-full h-full overflow-hidden rounded-3xl">
      <div
        className={classNames('absolute z-10 flex items-center p-6', {
          'right-0 top-0': !hasLocation,
        })}
      >
        <div className="absolute flex w-8 h-8 rounded-full bg-blue-500/60 animate-pingSlow" />
        <Avatar size={32} src={avatar} name={displayName || ''} />
      </div>

      {!hasLocation && (
        <div className="absolute bottom-0 left-0 z-10 flex flex-col p-6">
          <small className="text-sm font-black tracking-wider uppercase text-secondary">
            Location
          </small>
          <p className="text-xl font-black text-secondary">
            No location has been set.
          </p>
        </div>
      )}

      <div className="absolute inset-0 w-full h-full -z-1">
        <Loader height="100%" width="100%">
          <rect x={0} y={0} height="100%" width="100%" />
        </Loader>
      </div>

      <img
        alt={mapLocation}
        src={`${origin}/api/map/image?${urlSearchParams.toString()}`}
        className={classNames(
          'absolute w-full pointer-events-none select-none object-cover',
          { blur: !hasLocation }
        )}
      />
    </div>
  );
}
