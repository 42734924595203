import React, { useCallback, useEffect, useState } from 'react';
import NextButton from './components/NextButton';
import StepHeader from './components/StepHeader';
import { OnboardingStepProps } from './OnboardingStepProps';
import { uuid } from 'utils/toolbox';
import { useUpdateAtom } from 'jotai/utils';
import { optimisticTodosAtom } from 'components/Todos/todosAtoms';
import { CategoryUpsertInput } from '@graphql-types@';
import { atom } from 'jotai';
import RadioList from 'components/Settings/controls/RadioList';
import { useUpdateTodosMutation } from 'graphql/mutations/UpdateTodos.graphql';

const todoListsOptions = [
  {
    id: uuid(),
    listTitles: ['Today', 'This week', 'Later'],
  },
  {
    id: uuid(),
    listTitles: ['Work', 'Personal'],
  },
  {
    id: uuid(),
    listTitles: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  },
];

export const todoCategoriesOnboardAtom = atom<CategoryUpsertInput[]>([]);

export default React.memo(function TodoLists({
  onNext,
  isActive,
}: OnboardingStepProps): JSX.Element | null {
  const [selectedTodoListId, setSelectedTodoListId] = useState('');
  const [todoCategoriesOnBoard, setCategoriesOnboard] = useState<
    CategoryUpsertInput[]
  >([]);

  const optSetCategories = useUpdateAtom(optimisticTodosAtom);
  const [, serverSetCategories] = useUpdateTodosMutation();

  const [hasInteracted, setHasInteracted] = useState(false);

  const handleOnSelect = useCallback(
    (optionId: string) => {
      setSelectedTodoListId(optionId);
    },
    [setSelectedTodoListId]
  );

  const isTodoSelected = useCallback(
    (id: string) => id === selectedTodoListId,
    [selectedTodoListId]
  );

  const save = () => {
    optSetCategories({
      categories: todoCategoriesOnBoard,
    });

    serverSetCategories({
      objects: {
        categories: todoCategoriesOnBoard,
      },
    });
    onNext();
  };

  useEffect(() => {
    if (!selectedTodoListId) return;

    const selectedCategory = todoListsOptions.find(
      (t) => t.id === selectedTodoListId
    );

    setHasInteracted(true);

    if (!selectedCategory) return;

    const categories: CategoryUpsertInput[] = selectedCategory.listTitles.map(
      (title) => {
        return {
          id: uuid(),
          lastClientUpdate: new Date().toISOString(),
          name: title,
          after: null,
        };
      }
    );

    setCategoriesOnboard(categories);
  }, [selectedTodoListId, setCategoriesOnboard, hasInteracted]);

  return (
    <>
      <StepHeader
        accentColor="text-green-500"
        title="todo lists"
        primary="What do you call your todo lists?"
        secondary="You can create your own later"
      />

      <RadioList
        items={todoListsOptions.map(({ id, listTitles }) => {
          return {
            title: listTitles.join(', '),
            selected: isTodoSelected(id),
            onSelect: () => handleOnSelect(id),
          };
        })}
      />

      <NextButton
        onNext={save}
        onSkip={onNext}
        isEnabled={isActive && hasInteracted}
        hideSkip={false}
      />
    </>
  );
});
