/* 41a4dcec8a9ed6fcb892a7124e7849723ba22009
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SpotifyCurrentlyPlayingQueryVariables = Types.Exact<{
  targetUser: Types.Scalars['String'];
}>;


export type SpotifyCurrentlyPlayingQuery = { __typename?: 'query_root', spotifyCurrentlyPlaying?: { __typename?: 'SpotifyTrack', title: string, photo: string, id: string, duration: number, artist: string, album: string } | null };


export const SpotifyCurrentlyPlayingDocument = gql`
    query SpotifyCurrentlyPlaying($targetUser: String!) {
  spotifyCurrentlyPlaying(targetUser: $targetUser) {
    title
    photo
    id
    duration
    artist
    album
  }
}
    `;

export function useSpotifyCurrentlyPlayingQuery(options: Omit<Urql.UseQueryArgs<SpotifyCurrentlyPlayingQueryVariables>, 'query'>) {
  return Urql.useQuery<SpotifyCurrentlyPlayingQuery>({ query: SpotifyCurrentlyPlayingDocument, ...options });
};