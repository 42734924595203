import { NewEventRsvpEnum } from '@graphql-types@';
import { AvatarStatus } from 'joy/Avatar';
import { ColorFamily } from '@graphql-types@';

export function getAvatarStatus(rsvpStatus: NewEventRsvpEnum): AvatarStatus {
  switch (rsvpStatus) {
    case NewEventRsvpEnum.Yes:
      return ColorFamily.Green;
    case NewEventRsvpEnum.No:
      return ColorFamily.Red;
    case NewEventRsvpEnum.NotInvited:
      return ColorFamily.Orange;
    case NewEventRsvpEnum.Unknown:
      return ColorFamily.Gray;
  }
}
