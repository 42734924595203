/* 1c03caf9d4fb313ed4a2cac57d97a6468de027e0
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SpotifyPauseSongMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SpotifyPauseSongMutation = { __typename?: 'mutation_root', spotifyPauseSong?: { __typename?: 'SpotifyPauseTrack', deviceId?: string | null, type?: string | null } | null };


export const SpotifyPauseSongDocument = gql`
    mutation SpotifyPauseSong {
  spotifyPauseSong(source: "computer") {
    deviceId
    type
  }
}
    `;

export function useSpotifyPauseSongMutation() {
  return Urql.useMutation<SpotifyPauseSongMutation, SpotifyPauseSongMutationVariables>(SpotifyPauseSongDocument);
};