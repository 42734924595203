import { NewEventRsvpEnum } from '@graphql-types@';
import classNames from 'classnames';
import { useEventCardFocusContext } from 'contexts/eventCardFocus';
import { useContactsValue } from 'hooks/useContacts';
import Avatar from 'joy/Avatar';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import React from 'react';
import { EventColor } from 'types/color';
import { Attendee } from 'types/events';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { getAvatarStatus } from './utils';

type MoreAttendeesBubbleProps = {
  attendees: Attendee[];
  cardColorMap: EventColor;
  onClick: () => void;
};

function getRsvpConsensus(attendees: Attendee[]): NewEventRsvpEnum | null {
  const rsvpConsensus = attendees[0].RSVP;

  for (let i = 1; i < attendees.length; i++) {
    if (attendees[i].RSVP !== rsvpConsensus) {
      return null;
    }
  }

  return rsvpConsensus;
}

export default function MoreAttendeesBubble({
  attendees,
  cardColorMap,
  onClick,
}: MoreAttendeesBubbleProps) {
  const { hasFocus } = useEventCardFocusContext();
  const rsvpStatus = getRsvpConsensus(attendees);
  const contacts = useContactsValue();
  let colorMap = EVENT_COLOR_MAP['gray'];

  if (hasFocus) {
    colorMap = cardColorMap;
  } else {
    if (rsvpStatus === NewEventRsvpEnum.Yes) {
      colorMap = EVENT_COLOR_MAP['green'];
    } else if (rsvpStatus === NewEventRsvpEnum.No) {
      colorMap = EVENT_COLOR_MAP['red'];
    }
  }

  return (
    <Tooltip
      placement="bottom-center"
      content={
        <div className="flex flex-1 flex-col space-y-1.5 truncate">
          {attendees.map((attendee) => {
            const attendeeAvatarSrc =
              attendee.avatar ||
              contacts.find(
                (contact) => contact.emailAddress === attendee.email
              )?.avatar ||
              undefined;

            return (
              <div key={attendee.email} className="flex items-center">
                <Avatar
                  src={attendeeAvatarSrc}
                  name={attendee.email}
                  status={getAvatarStatus(attendee.RSVP)}
                  statusClassName="border-black border-2"
                />
                <div className="ml-2 flex flex-col">
                  <small className="text-xs font-medium">
                    {attendee.displayName || attendee.email}
                  </small>
                </div>
              </div>
            );
          })}
        </div>
      }
    >
      <Button
        className={classNames('rounded-full text-s font-black', {
          [`${colorMap.button} ${colorMap.text} hover:!bg-opacity-100 focus:!bg-opacity-100`]:
            hasFocus,
        })}
        variant="popoverIcon"
        onClick={onClick}
      >
        +{attendees.length}
      </Button>
    </Tooltip>
  );
}
