import {
  areEventsOverlapped,
  areHeadersOverlapped,
  EventData,
  excludeEvent,
  findNearestParent,
  getAllOverlaps,
  sortEvents,
} from './overlap';
import OverlapNode, { OptimizedEventOverlap } from './OverlapNode';
import { groupBy } from 'lodash';

export type OverlapInfo = Record<string, OptimizedEventOverlap>;

class OverlapTree {
  private events: EventData[];
  private eventIndexes: Record<string, number> = {};
  private overlaps: Record<string, EventData[]>;
  private nodes: Record<string, OverlapNode> = {};

  constructor(events: EventData[]) {
    this.events = events.sort(sortEvents);
    this.overlaps = getAllOverlaps(this.events);

    const eventsByDay = groupBy(events, (e) => e.startAt.weekday);
    for (const dayEvents of Object.values(eventsByDay)) {
      dayEvents.forEach((event, index) => {
        this.eventIndexes[event.id] = index;
      });
    }

    this.build();
    this.optimize();
  }

  private build(): void {
    for (const event of this.events) {
      this.addNode(event);
    }
  }

  private optimize(): void {
    for (const event of this.events) {
      const node = this.get(event.id);
      const roots = Object.values(this.nodes).filter(
        (node) => node.level === 0 && !node.data.isAllDay
      );

      const overlappingRoots = roots.filter(
        (other) =>
          other.id !== node.id && areEventsOverlapped(other.data, node.data)
      );

      const rootIsBusy = overlappingRoots.length > 0;

      const overlapsRootTitle = areHeadersOverlapped(node.root.data, node.data);

      const multipleOverlaps =
        !overlapsRootTitle &&
        node.parent?.info.overlapsParentTitle &&
        node.parent?.parent?.info.overlapsParentTitle;

      const nodeIsRoot = node.root.id === node.id;

      const overlapWithTwins =
        !overlapsRootTitle &&
        node.level >= 1 &&
        node.depth &&
        !node.info.overlapsParentTitle &&
        (node.parent?.info.twins ?? 0) > 0;

      const resetLevel = [!rootIsBusy, multipleOverlaps, overlapWithTwins].some(
        (c) => c
      );

      if (!nodeIsRoot && !node.parent?.optimized && resetLevel) {
        node.moveTo(undefined);
      }
    }
  }

  get(id: string): OverlapNode {
    return this.nodes[id];
  }

  debug(): any {
    const info: any = {};
    for (const node of Object.values(this.nodes)) {
      info[node.id] = node.debug();
    }

    return info;
  }

  plain(): OverlapInfo {
    const info: OverlapInfo = {};

    for (const node of Object.values(this.nodes)) {
      info[node.id] = node.plain();
    }

    return info;
  }

  private addNode(event: EventData) {
    if (this.nodes[event.id]) {
      return;
    }

    const parent = this.getParent(event);
    const node = parent ? parent.addChild(event) : new OverlapNode(event);
    const overlaps = this.overlaps[event.id];
    const globalLevel = this.eventIndexes[event.id];

    node.setOverlaps(overlaps);
    node.setGlobalLevel(globalLevel);

    this.nodes[node.id] = node;
  }

  private getParent(event: EventData) {
    if (event.isAllDay) {
      return null;
    }

    const eventOverlaps = excludeEvent(this.overlaps[event.id], event.id);
    const parentEvent = findNearestParent(event, eventOverlaps);
    if (!parentEvent) {
      return null;
    }

    if (!this.nodes[parentEvent.id]) {
      this.addNode(parentEvent);
    }

    return this.nodes[parentEvent.id];
  }
}

export default OverlapTree;
