import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import useHotkey from 'hooks/useHotkey';
import Button from 'joy/Button';

type Props = {
  onNext: () => void;
  onSkip?: () => void;
  textPrimary?: string;
  isEnabled?: boolean;
  isFocused?: boolean;
  hideSkip?: boolean;
};

export default React.memo(function NextButton({
  onNext,
  onSkip,
  textPrimary,
  isEnabled = true,
  isFocused,
  hideSkip = true,
}: Props): JSX.Element {
  const ref = useRef<HTMLButtonElement>(null);
  useHotkey(
    'enter',
    { enabled: isEnabled && isFocused, scope: 'all' },
    onNext,
    [isEnabled, onNext, isFocused]
  );

  useHotkey(
    'tab',
    {
      enabled: isFocused,
      scope: 'all',
    },
    (e) => {
      if (isFocused) {
        e.preventDefault();
      }
    },
    [isFocused]
  );

  useEffect(() => {
    if (!ref.current) return;

    if (isFocused) {
      ref.current.focus();
    } else {
      ref.current.blur();
    }
  });

  return useMemo(
    () => (
      <div className="flex justify-end space-x-3">
        <Button
          className={classNames(
            'z-50 mt-10 rounded-8px  px-8 py-3  font-semibold text-gray-600 dark:text-gray-400',
            'focus:scale-105 focus-visible:scale-105',
            'hover:bg-gray-100/60 dark:hover:bg-gray-700/60',
            { 'opacity-0': hideSkip }
          )}
          onClick={onSkip || onNext}
          tabIndex={0}
        >
          Skip
        </Button>
        <Button
          ref={ref}
          className={classNames(
            'z-50 mt-10 rounded-8px bg-rose-400 px-8 py-3 text-white',
            'hover:bg-rose-400/80',
            'focus:scale-98 focus-visible:scale-105 focus-visible:bg-rose-400/80',
            'disabled:scale-100 disabled:cursor-default disabled:bg-gray-100 disabled:text-gray-400',
            'dark:disabled:bg-gray-700/50 dark:disabled:text-gray-600'
          )}
          disabled={!isEnabled}
          onClick={onNext}
          tabIndex={0}
        >
          <span className={`text-primary-800 font-semibold`}>
            {textPrimary || 'Next'}
          </span>
        </Button>
      </div>
    ),
    [isEnabled, onNext, textPrimary, hideSkip, onSkip]
  );
});
