import { Active, useDndContext } from '@dnd-kit/core';
import { DraggableNodes } from '@dnd-kit/core/dist/store';
import { atom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useEffect } from 'react';

export const activeDndAtom = atom<Active | null>(null);
export const isDraggingAtom = atom((get) => get(activeDndAtom) !== null);
export const draggableNodesAtom = atom<DraggableNodes>({});

export function useIsActiveDnd() {
  return useAtomValue(isDraggingAtom);
}

export function useActiveDnd() {
  return useAtomValue(activeDndAtom);
}

export function useDraggableNodes() {
  return useAtomValue(draggableNodesAtom);
}

export function SyncDnd(): null {
  const { active, draggableNodes } = useDndContext();
  const setActive = useUpdateAtom(activeDndAtom);
  const setDraggableNodes = useUpdateAtom(draggableNodesAtom);

  useEffect(() => {
    setActive(active);
  }, [active, setActive]);
  useEffect(() => {
    setDraggableNodes(draggableNodes);
  }, [draggableNodes, setDraggableNodes]);

  return null;
}
