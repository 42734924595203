import classNames from 'classnames';
import IconVerified from 'components/Icons/IconVerified';
import { SettingsPageType } from 'components/Settings/types';
import { getStatusText } from 'components/Status/utils';
import { useUpdateModal, useUpdateSettingPageModal } from 'hooks/useModal';
import useUserStatus from 'hooks/useUserStatus';
import Avatar from 'joy/Avatar';
import Button from 'joy/Button';
import React from 'react';
import { ModalType } from 'types/modal';
import { AMIE_PATTERN_BG_HEIGHT } from 'utils/constants';
import ProfileFollowToggle from './ProfileFollowToggle';
import ProfileHeaderInfo from './ProfileHeaderInfo';

interface Props {
  isSelf: boolean;
  isExternalUser: boolean;
  avatar: string;
  displayName: string | null;
  email: string;
  birthday?: string | null;
  twitter?: string;
  location?: string;
  verified?: boolean;
  handle?: Nullable<string>;
  contactId: string;
}

export default function ProfileHeader({
  isSelf,
  isExternalUser,
  avatar,
  displayName,
  email,
  birthday,
  handle,
  twitter,
  location,
  contactId,
  verified = false,
}: Props) {
  const { openModal } = useUpdateModal();
  const setSettingsPage = useUpdateSettingPageModal();
  const status = useUserStatus({ userEmail: email });
  const name = displayName || email;

  return (
    <div className="relative flex flex-col overflow-hidden rounded-3xl bg-white  shadow-feint dark:bg-gray-750">
      <div className="absolute inset-0 h-full w-full">
        <div
          className="bg-pattern-amie h-full w-full animate-backgroundScroll"
          style={{
            height: AMIE_PATTERN_BG_HEIGHT * 2,
            backgroundSize: '100%',
          }}
        />
      </div>
      <div className="absolute inset-0 h-full w-full bg-gradient-to-t from-white to-white/20 dark:from-gray-750 dark:to-gray-750/20" />

      <div className="flex h-56 w-full items-center overflow-hidden px-7">
        <div className="relative flex rounded-full shadow-lg">
          <Avatar size={130} name={name} src={avatar} />

          <div
            className={classNames(
              'absolute left-20 -bottom-2.5 z-20 flex whitespace-nowrap rounded-full border-4 border-white bg-gradient-to-t px-3 py-1.5 text-s font-black text-white dark:border-gray-750',
              {
                'from-green-600 to-green-500':
                  status?.status === 'free' && !isExternalUser,
                'from-red-600 to-red-500':
                  status?.status === 'busy' && !isExternalUser,
                'from-gray-600 to-gray-500':
                  status?.status === 'away' || isExternalUser,
              }
            )}
          >
            {isExternalUser || status?.status === 'away'
              ? `¯\\_(ツ)_/¯`
              : getStatusText(status)}
          </div>
        </div>
      </div>
      <div className="relative flex w-full items-center pb-8 pl-8 pr-8">
        <div className="flex flex-col">
          <div className="flex items-center gap-1">
            <h4 className="text-2xl font-black leading-none">{name}</h4>
            {verified && (
              <span className=" text-rose-300">
                <IconVerified />
              </span>
            )}
          </div>
          {!isExternalUser && (
            <h4 className="text-secondary mt-1.5 text-base font-semibold leading-none">
              {handle ? `@${handle}` : email}
            </h4>
          )}

          <ProfileHeaderInfo
            twitter={twitter}
            location={location}
            birthday={birthday}
          />
        </div>

        <div className="mt-auto ml-auto flex items-center gap-2">
          {isSelf && (
            <Button
              className="rounded-lg border border-gray-150 px-6 py-3 text-sm font-semibold hover:bg-gray-150 dark:border-gray-600 dark:hover:bg-gray-600"
              onClick={() => {
                setSettingsPage(SettingsPageType.Profile);
                openModal(ModalType.Settings);
              }}
            >
              Edit profile
            </Button>
          )}

          {!isExternalUser && !isSelf && <ProfileFollowToggle id={contactId} />}
        </div>
      </div>
    </div>
  );
}
