import { NewEventVisibilityEnum } from '@graphql-types@';
import classNames from 'classnames';
import IconLockedClosed from 'components/Icons/IconLockClosed';
import IconLockedOpen from 'components/Icons/IconLockOpen';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import React, { useCallback } from 'react';
import { IGridEvent } from 'types/events';

interface Props {
  className?: string;
  value: IGridEvent['visibility'];
  onChange: (value: IGridEvent['visibility']) => void;
  readOnly: boolean;
}

export default function EventVisibility({
  className,
  value,
  onChange,
  readOnly,
}: Props): React.ReactElement {
  const handleChange = useCallback(() => {
    if (readOnly) return;

    const visibility =
      value === NewEventVisibilityEnum.Default
        ? NewEventVisibilityEnum.Private
        : NewEventVisibilityEnum.Default;

    onChange(visibility);
  }, [onChange, readOnly, value]);

  return (
    <Tooltip
      content={
        value === NewEventVisibilityEnum.Private
          ? readOnly
            ? 'Private'
            : 'Make public'
          : readOnly
          ? 'Public'
          : 'Make private'
      }
    >
      <Button
        onClick={handleChange}
        className={classNames({ ['cursor-default']: readOnly }, className)}
        variant="popoverIcon"
        disabled={readOnly}
      >
        {value === NewEventVisibilityEnum.Private ? (
          <IconLockedClosed />
        ) : (
          <IconLockedOpen />
        )}
      </Button>
    </Tooltip>
  );
}
