import { IGridEvent } from 'types/events';
import OverlapTree from './OverlapTree';
import OverlapNode from './OverlapNode';
import { COLUMN_END_PADDING } from '../../../components/Grid/utils';

export interface StylesOverride {
  index?: number;
  styles: {
    width: string;
    marginLeft: string;
    zIndex: number;
  };
}

export const DEFAULT_GRID_EVENT_WIDTH = `(100% - ${COLUMN_END_PADDING}px)`;

class OverlapStyler {
  public tree: OverlapTree;
  private styles: Record<string, StylesOverride> = {};

  constructor(readonly events: IGridEvent[]) {
    this.tree = new OverlapTree(events);

    for (const event of events) {
      this.styles[event.id] = this.getEventStyles(event);
    }
  }

  get(id: string): StylesOverride {
    return (
      this.styles[id] || {
        index: 0,
        styles: {
          width: DEFAULT_GRID_EVENT_WIDTH,
          marginLeft: '0px',
          zIndex: 0,
        },
      }
    );
  }

  debug() {
    return this.styles;
  }

  private getEventStyles(event: IGridEvent): StylesOverride {
    if (this.styles[event.id]) {
      return this.styles[event.id];
    }

    const node = this.tree.get(event.id);
    if (node.data.isAllDay) {
      return this.getAllDayStyles(node);
    } else {
      return this.calculateStyles(node);
    }
  }

  private getAllDayStyles(node: OverlapNode): StylesOverride {
    return {
      index: node.info.index,
      styles: {
        width: DEFAULT_GRID_EVENT_WIDTH,
        marginLeft: '0px',
        zIndex: 0,
      },
    };
  }

  private calculateStyles(node: OverlapNode): StylesOverride {
    const defaultWidth = DEFAULT_GRID_EVENT_WIDTH;
    const overlap = node.plain();

    const parent = node.parent
      ? this.getEventStyles(node.parent.data as unknown as IGridEvent)
      : null;

    const root =
      node.root.id !== node.id
        ? this.getEventStyles(node.root.data as unknown as IGridEvent)
        : null;

    const baseMargin: string = parent?.styles.marginLeft ?? '0px';
    const baseWidth: string = parent?.styles.width ?? defaultWidth;

    const rootOptimized = node.root.optimized;

    const offset: string = parent || overlap.resetLevel ? `10px` : '0px';
    let width = `(${baseWidth} - ${offset})`;
    let marginLeft = `(${baseMargin} + ${overlap.resetLevel ? '0px' : offset})`;

    if (overlap.info.overlapsParentTitle) {
      const overallDepth = overlap.tree.titleDepth;
      const level = overlap.tree.titleLevel;
      const inverseLevel = overallDepth - level;

      let base = rootOptimized && root ? root.styles.width : defaultWidth;
      base = node.level > 1 && rootOptimized ? defaultWidth : base;

      width = `(${base} / ${overallDepth} * ${inverseLevel})`;
      marginLeft = `(${base} / ${overallDepth} * ${level})`;
    } else if (overlap.info.twins) {
      const total = overlap.info.twins + 1;
      const inverseIndex = total - overlap.index;
      width = `(${defaultWidth} / ${total} * ${inverseIndex})`;
      marginLeft = `(${defaultWidth} / ${total} * ${overlap.index})`;
    }

    if (node.optimized && overlap.prevInfo?.overlapsParentTitle) {
      const total = node.prevParent!.level + 1;
      const space = (total - 1) / total;

      width = `(${defaultWidth} * ${space})`;
    }

    return {
      index: overlap.index,
      styles: {
        width,
        marginLeft,
        zIndex: overlap.globalLevel,
      },
    };
  }
}

export default OverlapStyler;
