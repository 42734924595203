import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import useHotkey from 'hooks/useHotkey';
import { motion } from 'framer-motion';
import Colleagues from './onboarding-steps/Colleagues';
import VideoCalls from './onboarding-steps/VideoCalls';
import TodoLists from './onboarding-steps/TodoLists';
import EventColor from './onboarding-steps/EventColor';
import OnboardingDownloadApp from './onboarding-steps/OnboardingDownloadApp';

type Props = {
  onFinish: () => void;
};

enum OnboardSteps {
  EventColor,
  VideoCalls,
  TodoLists,
  Colleagues,
  OnboardingDownloadApp,
}

export default React.memo(function NewUserSettings({
  onFinish,
}: Props): JSX.Element {
  const [stepComponents, setStepComponents] = useState([
    EventColor,
    VideoCalls,
    TodoLists,
    Colleagues,
    OnboardingDownloadApp,
  ]);

  const [stepIndex, setStepIndex] = useState<OnboardSteps>(
    OnboardSteps.EventColor
  );
  const lastStepIndex = useRef(0);

  const onLastStep = useMemo(
    () => stepIndex === stepComponents.length - 1,
    [stepIndex, stepComponents]
  );

  const isNotFirstStep = useMemo(() => stepIndex > 0, [stepIndex]);
  const isNotFinalStep = useMemo(
    () => stepIndex < stepComponents.length - 1,
    [stepIndex, stepComponents]
  );

  const goToNext = useCallback(() => {
    if (stepIndex === stepComponents.length - 1) return;
    lastStepIndex.current = stepIndex;
    setStepIndex(stepIndex + 1);
  }, [stepIndex, setStepIndex, stepComponents]);

  const goToPrev = useCallback(() => {
    if (stepIndex === 0) return;

    lastStepIndex.current = stepIndex;
    setStepIndex(stepIndex - 1);
  }, [stepIndex, setStepIndex]);

  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    if (isScrolling) {
      setTimeout(() => {
        setIsScrolling(false);
      }, 800);
    }
  }, [isScrolling]);

  useEffect(() => {
    const onScroll = ({ deltaY }: WheelEvent) => {
      if (isScrolling) return;

      if (deltaY > 20 && !onLastStep) {
        setIsScrolling(true);
        goToNext();
      } else if (deltaY < -20) {
        setIsScrolling(true);
        goToPrev();
      }
    };

    document.addEventListener('wheel', onScroll);

    return () => {
      document.removeEventListener('wheel', onScroll);
    };
  }, [onLastStep, stepIndex, goToNext, goToPrev, setIsScrolling, isScrolling]);

  useHotkey('tab', 'onboarding', (e) => {
    e.preventDefault();
  });

  useHotkey('shift+tab', 'onboarding', (e) => {
    e.preventDefault();
  });

  useHotkey('up', { scope: 'all', enabled: isNotFirstStep }, goToPrev, [
    goToPrev,
  ]);

  useHotkey(
    'down',
    {
      scope: 'all',
      enabled: isNotFinalStep,
    },
    () => goToNext(),
    [goToNext]
  );

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      className={`fixed z-50 m-auto flex
      w-full
            flex-col
            backdrop-blur-sm
            backdrop-filter
            `}
    >
      {stepComponents.map((Step, i: number) => (
        <StepWrapper
          key={i}
          index={i}
          stepIndex={stepIndex}
          stepsLength={stepComponents.length}
        >
          <div>
            <Step
              onNext={() => (isNotFinalStep ? goToNext() : onFinish())}
              isActive={stepIndex === i}
              onShouldHideStep={() => {
                setStepComponents(
                  stepComponents.filter((_c, indexComponent) => {
                    return i !== indexComponent;
                  })
                );
              }}
            />
          </div>
        </StepWrapper>
      ))}
    </motion.div>
  );
});

const StepWrapper = ({
  children,
  index,
  stepIndex,
  stepsLength,
}: {
  children: React.ReactNode;
  index: number;
  stepIndex: number;
  stepsLength: number;
}) => {
  const viewPortHeight = window ? window.innerHeight : 0;

  const onboardVariants = useMemo(() => {
    return new Array(stepsLength).fill(null).reduce((acc, _c, index) => {
      return {
        ...acc,
        [index]: (custom: number) => {
          return {
            y: -Math.abs(index * viewPortHeight),
            opacity: custom === stepIndex ? 1 : 0,
            transition: {
              delay: 0,
              duration: custom !== stepIndex ? 0.6 : 0.3,
              ease: 'easeInOut',
            },
          };
        },
      };
    }, {});
  }, [stepIndex, viewPortHeight, stepsLength]);

  return (
    <motion.div
      className="m-auto flex h-screen w-full max-w-xl flex-col justify-center"
      custom={index}
      variants={onboardVariants}
      animate={stepIndex.toString()}
    >
      {children}
    </motion.div>
  );
};
