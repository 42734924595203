import { selectedCalendarLinkAtom } from 'hooks/calendarLink/creation/useSelectedCalendarLink';
import { gridEventsFamily } from 'hooks/events/eventAtoms';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { calendarModeAtom } from 'hooks/useCalendarMode';
import {
  eventsSelectionAtom,
  fullSelectionEventsAtom,
  useSetEventsSelection,
} from 'hooks/useEventsSelection';
import useHotkey, { UseHotkeyCallback } from 'hooks/useHotkey';
import { useUpdateModal } from 'hooks/useModal';
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { ModalType } from 'types/modal';
import { isString } from './utils';
import { selectedSlotIdAtom } from 'hooks/calendarLink/creation/useSelectedSlotId';
import { calendarLinksFamily } from 'hooks/calendarLink/calendarLinkAtoms';
import { throttle } from 'lodash';
import { NewEventRsvpEnum, NewEventVisibilityEnum } from '@graphql-types@';

export default function useEventShortcuts(): void {
  const { openModal } = useUpdateModal();
  const { clearEventsSelection, setEventsSelection } = useSetEventsSelection();

  const { deleteGridEvent, saveGridEvent, duplicateGridEvent } =
    useUpdateGridEvent();

  /* Delete */
  const handleDelete: UseHotkeyCallback = useAtomCallback(
    useCallback(
      (get, set) => {
        const selectedCalendarLink = get(selectedCalendarLinkAtom);
        const selectedSlotId = get(selectedSlotIdAtom);

        if (
          get(calendarModeAtom) !== 'default' &&
          selectedCalendarLink &&
          selectedSlotId
        ) {
          set(calendarLinksFamily(selectedCalendarLink.id), {
            ...selectedCalendarLink,
            slots: selectedCalendarLink.slots.filter(
              (slot) => slot.id !== selectedSlotId
            ),
          });
          set(selectedSlotIdAtom, null);

          return;
        }

        const eventsSelection = get(eventsSelectionAtom);
        if (eventsSelection.length === 0) return;
        clearEventsSelection();
        eventsSelection.forEach((eventId) => deleteGridEvent({ eventId }));
      },
      [clearEventsSelection, deleteGridEvent]
    )
  );

  useHotkey('delete, backspace', 'modal', handleDelete);
  useHotkey('delete, backspace', 'global', handleDelete);

  /* Duplicate */
  const handleDuplicate: UseHotkeyCallback = useAtomCallback(
    useCallback(
      async (get, _set, keyboardEvent) => {
        const eventsSelection = get(eventsSelectionAtom);
        if (eventsSelection.length === 0) return;
        keyboardEvent.preventDefault();

        const toSelectAfter = await Promise.all(
          eventsSelection.map((id) =>
            duplicateGridEvent(id).then(async (newId) => newId)
          )
        );
        setEventsSelection(() => toSelectAfter.filter(isString));
      },
      [duplicateGridEvent, setEventsSelection]
    )
  );

  useHotkey('command+d, ctrl+d', 'modal', handleDuplicate);

  /* Nudge (-+15 min) */
  const NUDGE_DIFF_IN_MINS = 15;

  const nudgeEvent = useAtomCallback(
    useCallback(
      (get, _set, direction: 'up' | 'down') => {
        const eventsSelection = get(eventsSelectionAtom);
        eventsSelection.forEach((eventId) => {
          const event = get(gridEventsFamily(eventId));
          if (!event || !event.canEdit) {
            return;
          }

          const minuteDiff =
            direction === 'up' ? -NUDGE_DIFF_IN_MINS : NUDGE_DIFF_IN_MINS;
          saveGridEvent({
            id: eventId,
            startAt: event.startAt.plus({ minutes: minuteDiff }),
            endAt: event.endAt.plus({ minutes: minuteDiff }),
          });
        });
      },
      [saveGridEvent]
    )
  );

  /* Toggle RSVP when pressing "P" */
  const handleRSVP: UseHotkeyCallback = useAtomCallback(
    useCallback(
      async (get, _set, e) => {
        const eventsSelection = get(eventsSelectionAtom);
        if (eventsSelection.length === 0) return;
        e.preventDefault();

        eventsSelection.forEach((eventId) => {
          saveGridEvent({
            id: eventId,
            rsvp: e.key === 'y' ? NewEventRsvpEnum.Yes : NewEventRsvpEnum.No,
          });
        });
      },
      [saveGridEvent]
    )
  );

  const handlePrivate: UseHotkeyCallback = useAtomCallback(
    useCallback(
      async (get, _set, e) => {
        const eventsSelection = get(eventsSelectionAtom);

        if (eventsSelection.length === 0) return;
        e.preventDefault();

        const fullEventsSelection = get(fullSelectionEventsAtom);

        const ownedEvents = [...fullEventsSelection].filter(
          (e) => e.isOwnEvent
        );

        const allEventsPrivate = [...ownedEvents].every(
          (e) => e?.visibility == NewEventVisibilityEnum.Private
        );
        const visibility = allEventsPrivate
          ? NewEventVisibilityEnum.Public
          : NewEventVisibilityEnum.Private;

        ownedEvents.forEach((selectedEvent) => {
          saveGridEvent({
            id: selectedEvent.id,
            visibility,
          });
        });
      },
      [saveGridEvent]
    )
  );

  useHotkey('shift+up', 'modal', () => nudgeEvent('up'), [nudgeEvent]);
  useHotkey('shift+down', 'modal', () => nudgeEvent('down'), [nudgeEvent]);

  useHotkey('enter, space', { scope: 'global' }, (e) => {
    e.preventDefault();
    openModal(ModalType.Event);
  });

  useHotkey('enter,space ', 'modal', (e) => {
    e.preventDefault();
    clearEventsSelection();
  });

  useHotkey('y, n', { scope: 'global' }, handleRSVP, []);

  useHotkey('p', { scope: 'global' }, handlePrivate, []);
  useHotkey('p', { scope: 'modal' }, handlePrivate, []);

  const updateEventDoneAt = useAtomCallback(
    useCallback(
      (get) => {
        const selectedEvents = get(fullSelectionEventsAtom);
        if (selectedEvents.length === 0) return;

        const areAllDone = selectedEvents.every((event) => !!event.doneAt);

        // Toggle all selected events to Done / Undone
        selectedEvents.forEach(({ calendarId, id }) => {
          saveGridEvent({
            calendarId,
            id,
            doneAt: areAllDone ? null : new Date().toISOString(),
          });
        });
      },
      [saveGridEvent]
    )
  );

  useHotkey(
    'd',
    {
      scope: 'all',
      keydown: false,
      keyup: true,
    },
    throttle(updateEventDoneAt, 200)
  );
}
