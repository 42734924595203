import { userEmailAtom } from 'hooks/auth/authAtoms';
import { generateEventUUID } from 'hooks/events/helpers/eventsHelpers';
import { eventsSelectionAtom } from 'hooks/useEventsSelection';
import { useAtomCallback } from 'jotai/utils';
import React from 'react';
import { getCoordinates } from 'utils/mouseEvents';
import { useClickToCreateEvent } from './hooks/useClickToCreateEvent';

export default function GridAllDayInteractionHandler() {
  const createAllDayEvent = useClickToCreateEvent();

  const handleClick = useAtomCallback(
    (get, set, mouseEvent: React.MouseEvent<HTMLDivElement>) => {
      if (get(eventsSelectionAtom).length > 0) {
        set(eventsSelectionAtom, []);
        return;
      }
      const primaryCalendarId = get(userEmailAtom) || 'default';
      createAllDayEvent({
        id: generateEventUUID(primaryCalendarId),
        mouseEvent,
        initialCoordinates: getCoordinates(mouseEvent),
        openPopoverOnCreate: true,
        isAllDay: true,
      });
    }
  );

  return (
    <div
      role="presentation"
      onClick={handleClick}
      style={{ gridArea: '1 / 3 / end /end' }}
    />
  );
}
