import { NewEventRsvpEnum } from '@graphql-types@';
import classNames from 'classnames';
import useHotkey from 'hooks/useHotkey';
import Button from 'joy/Button';
import Dropdown, { DropdownItem } from 'joy/Dropdown';
import React, { useMemo } from 'react';
import type { IGridEvent } from 'types/events';
import EventRsvpStatus from './EventRsvpStatus';
import Tooltip from 'joy/Tooltip';

interface Props {
  attendeeCount: number;
  value: IGridEvent['rsvp'];
  onChange: (value: IGridEvent['rsvp']) => void;
}

export default function EventRsvp({ attendeeCount, value, onChange }: Props) {
  const hidden = attendeeCount === 0 || value === NewEventRsvpEnum.NotInvited;

  useHotkey(
    'y, n',
    { enabled: !hidden, scope: 'modal' },
    (_, { key }) => {
      onChange(key === 'y' ? NewEventRsvpEnum.Yes : NewEventRsvpEnum.No);
    },
    []
  );

  const menuItems: DropdownItem[] = useMemo(() => {
    const items: DropdownItem[] = [
      NewEventRsvpEnum.Yes,
      NewEventRsvpEnum.No,
    ].map((rsvp) => ({
      type: 'option',
      value: getRsvpTitle(rsvp),
      icon: <EventRsvpStatus className="mr-1" status={rsvp} />,
      onSelect: () => onChange(rsvp),
    }));

    return items;
  }, [onChange]);

  if (hidden) return null;

  return (
    <Dropdown items={menuItems}>
      <Tooltip content={'RSVP'}>
        <Button
          className={classNames(
            'flex items-center py-1 px-2 rounded-md bg-opacity-10 hover:bg-opacity-20 focus:bg-opacity-20 dark:bg-opacity-10 dark:hover:bg-opacity-20 dark:focus:bg-opacity-20 transition-colors',
            getRsvpBgClasses(value)
          )}
        >
          <EventRsvpStatus className="mr-1" status={value} />
          <p className="text-xs font-semibold">{getRsvpTitle(value)}</p>
        </Button>
      </Tooltip>
    </Dropdown>
  );
}

function getRsvpTitle(rsvp: NewEventRsvpEnum) {
  switch (rsvp) {
    case NewEventRsvpEnum.Yes:
      return 'Going';
    case NewEventRsvpEnum.No:
      return 'Not Going';
    case NewEventRsvpEnum.Unknown:
      return 'Going?';
    case NewEventRsvpEnum.NotInvited:
      return 'Not invited';
  }
}

function getRsvpBgClasses(rsvp: NewEventRsvpEnum) {
  switch (rsvp) {
    case NewEventRsvpEnum.Yes:
      return 'bg-green-500';
    case NewEventRsvpEnum.No:
      return 'bg-rose-500';
    case NewEventRsvpEnum.Unknown:
      return 'bg-gray-500 dark:bg-gray-300';
    case NewEventRsvpEnum.NotInvited:
      return 'bg-gray-500';
  }
}
