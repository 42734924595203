import classNames from 'classnames';
import { useUser } from 'hooks/auth/authAtoms';
import { AnimatePresence, motion } from 'framer-motion';
import Avatar from 'joy/Avatar';
import React, { useState } from 'react';
import { generateRandomBooleanArray } from 'utils/toolbox';

interface Props {
  avatar: string | undefined;
  displayName: string | null;
  count: number;
}

export default React.memo(function WidgetTogether({
  avatar,
  displayName,
  count,
}: Props) {
  const user = useUser();
  const [randomizedArray] = useState(generateRandomBooleanArray(10));

  const currentUserProfileInfo = {
    name: user?.displayName || '',
    src: user?.photo || '',
  };
  const otherUserProfileInfo = { name: displayName || '', src: avatar };

  if (!user) return null;

  return (
    <div className="relative flex h-full w-full flex-col overflow-hidden rounded-3xl bg-gradient-to-t from-purple-400 to-pink-200 p-6 text-white dark:from-purple-400/60 dark:to-pink-400/20">
      <div className="absolute inset-0 flex h-44 w-full overflow-hidden">
        <div className="flex h-full w-full rotate-6 flex-col gap-2.5">
          <div className="flex gap-2.5">
            {randomizedArray.map((value, index) => (
              <Avatar
                key={index}
                className="opacity-10 ring ring-black/20 grayscale dark:ring-white/10"
                size={40}
                {...(value ? currentUserProfileInfo : otherUserProfileInfo)}
              />
            ))}
          </div>
          <div className="ml-[-18px] flex gap-2.5">
            {randomizedArray.map((value, index) => (
              <Avatar
                key={index}
                className={classNames({
                  'opacity-10 ring ring-black/20 grayscale dark:ring-white/10':
                    index < 3 || index > 4,
                  'ring ring-black/10 dark:ring-white/30':
                    index === 3 || index === 4,
                })}
                size={40}
                {...(index === 3
                  ? currentUserProfileInfo
                  : index === 4
                  ? otherUserProfileInfo
                  : value
                  ? otherUserProfileInfo
                  : currentUserProfileInfo)}
              />
            ))}
          </div>
          <div className="flex gap-2.5">
            {randomizedArray.map((value, index) => (
              <Avatar
                key={index}
                className="opacity-10 ring ring-black/20 grayscale dark:ring-white/10"
                size={40}
                {...(value ? currentUserProfileInfo : otherUserProfileInfo)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="mt-auto flex flex-col gap-1">
        <AnimatePresence>
          {count && (
            <motion.div
              className="flex items-baseline gap-1"
              initial={{ y: 8, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 8, opacity: 0 }}
            >
              <p className="text-4xl font-black">{count}</p>
              <p className="text-xl font-medium">
                {count === 1 ? 'time' : 'times'}
              </p>
            </motion.div>
          )}
        </AnimatePresence>
        <small className="mt-0.5 text-sm font-black uppercase leading-snug tracking-wider text-purple-100 dark:text-purple-200">
          Together this year
        </small>
      </div>
    </div>
  );
});
