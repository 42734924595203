import { useUser } from 'hooks/auth/authAtoms';
import { useCalendarsQuery } from 'graphql/queries/calendars.graphql';
import { useOwnCalendarListQuery } from 'graphql/queries/OwnCalendarList.graphql';
import { allCalendarsAtom, ownCalendarsAtom } from 'hooks/useCalendars';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect } from 'react';
import React from 'react';
import Onboarding from '.';
import {
  OnboardingState,
  onboardingStepAtom,
  useOnboarding,
} from './useOnboarding';

export function OnboardingWrapper() {
  const { shouldOnboard } = useOnboarding();
  const setOnboardingStep = useUpdateAtom(onboardingStepAtom);

  useEffect(() => {
    if (shouldOnboard) {
      setOnboardingStep(OnboardingState.USER_SETTINGS);
    }
  }, [shouldOnboard, setOnboardingStep]);

  if (shouldOnboard) {
    return (
      <>
        <OnboardingSyncCalendars />
        <Onboarding />
      </>
    );
  }
  return null;
}
export function OnboardingSyncCalendars() {
  const user = useUser();
  const pause = !user; // null if not set

  const [ownCalendarsResults] = useOwnCalendarListQuery({
    pause,
  });

  const setOwnCalendars = useUpdateAtom(ownCalendarsAtom);

  useEffect(() => {
    if (!ownCalendarsResults.data) return;
    setOwnCalendars(ownCalendarsResults.data.ownCalendarList || []);
  }, [ownCalendarsResults.data, setOwnCalendars]);

  return null;
}
