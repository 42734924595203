import useSpotify from 'hooks/useSpotify';
import Button from 'joy/Button';
import React, { useCallback, useState } from 'react';
import WidgetSpotifyEmpty from './WidgetSpotifyEmpty';

interface Props {
  firstName: string | null;
  isSelf: boolean;
  email: string;
}

export default function WidgetSpotify({ email, firstName, isSelf }: Props) {
  const { currentlyPlaying: track, playSong, pauseSong } = useSpotify(email);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleSpotify = useCallback(() => {
    if (!track?.id) return;

    setIsLoading(true);

    if (isPlaying) {
      pauseSong()
        .then((res) => {
          if (!res.data?.spotifyPauseSong?.deviceId) return setIsPlaying(true);
          setIsPlaying(false);
        })
        .catch(() => setIsPlaying(true))
        .finally(() => setIsLoading(false));
    } else {
      playSong({ uri: track.id })
        .then((res) => {
          if (!res.data?.spotifyPlaySong?.deviceId) return setIsPlaying(false);
          setIsPlaying(true);
        })
        .catch(() => setIsPlaying(true))
        .finally(() => setIsLoading(false));
    }
  }, [isPlaying, pauseSong, playSong, track?.id]);

  if (!track)
    return <WidgetSpotifyEmpty isSelf={isSelf} firstName={firstName} />;

  return (
    <div className="flex flex-col w-full h-full p-6 text-white bg-gradient-to-t dark:bg-gradient-to-b from-spotify to-spotify/60 rounded-3xl">
      <div className="flex justify-between w-full">
        <div
          className="w-[156px] h-[156px] flex bg-cover rounded-xl"
          style={{
            backgroundImage: `url("${track.photo}")`,
          }}
        />

        <svg
          className="w-10 h-10"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 168 168"
        >
          <path
            fill="currentColor"
            d="m83.996 0.277c-46.249 0-83.743 37.493-83.743 83.742 0 46.251 37.494 83.741 83.743 83.741 46.254 0 83.744-37.49 83.744-83.741 0-46.246-37.49-83.738-83.745-83.738l0.001-0.004zm38.404 120.78c-1.5 2.46-4.72 3.24-7.18 1.73-19.662-12.01-44.414-14.73-73.564-8.07-2.809 0.64-5.609-1.12-6.249-3.93-0.643-2.81 1.11-5.61 3.926-6.25 31.9-7.291 59.263-4.15 81.337 9.34 2.46 1.51 3.24 4.72 1.73 7.18zm10.25-22.805c-1.89 3.075-5.91 4.045-8.98 2.155-22.51-13.839-56.823-17.846-83.448-9.764-3.453 1.043-7.1-0.903-8.148-4.35-1.04-3.453 0.907-7.093 4.354-8.143 30.413-9.228 68.222-4.758 94.072 11.127 3.07 1.89 4.04 5.91 2.15 8.976v-0.001zm0.88-23.744c-26.99-16.031-71.52-17.505-97.289-9.684-4.138 1.255-8.514-1.081-9.768-5.219-1.254-4.14 1.08-8.513 5.221-9.771 29.581-8.98 78.756-7.245 109.83 11.202 3.73 2.209 4.95 7.016 2.74 10.733-2.2 3.722-7.02 4.949-10.73 2.739z"
          />
        </svg>
      </div>

      <div className="flex items-center w-full mt-auto">
        <Button
          className="relative mt-1 mr-2 transition-transform duration-100 transform scale-100 hover:scale-110"
          onClick={toggleSpotify}
          title="Play this track on my Spotify"
          aria-label="Play this track on my Spotify"
          disabled={isLoading}
        >
          {isLoading && (
            <svg
              className="absolute w-12 h-12 animate-spin text-green opacity-30"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}

          {isPlaying ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={'h-12 w-12 opacity-90 inline-block'}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={'h-12 w-12 opacity-90 inline-block'}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </Button>

        <div className="flex flex-col truncate">
          <p className="text-xl font-black truncate">{track.title}</p>
          <small className="text-base font-medium truncate text-white/80">
            {track.artist}
          </small>
        </div>
      </div>
    </div>
  );
}
