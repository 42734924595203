import classNames from 'classnames';
import IconEyeClosed from 'components/Icons/IconEyeClosed';
import IconEyeOpened from 'components/Icons/IconEyeOpened';
import { useEventCardFocusContext } from 'contexts/eventCardFocus';
import { useSetVisibleGuestCalendars } from 'hooks/useVisibleCalendars';
import Button from 'joy/Button';
import React, { useCallback } from 'react';
import { EventColor } from 'types/color';
import { Attendee } from 'types/events';

type ToggleCalendarsVisibilityButtonProps = {
  attendees: Attendee[];
  shouldShow?: boolean;
  isSmall?: boolean;
  colorMap: EventColor;
};

export default function ToggleCalendarsVisibilityButton({
  shouldShow,
  attendees,
  isSmall,
  colorMap,
}: ToggleCalendarsVisibilityButtonProps) {
  const { addGuestCalendar, resetGuestCalendars } =
    useSetVisibleGuestCalendars();
  const { hasFocus } = useEventCardFocusContext();
  const showCalendars = useCallback(() => {
    attendees.map((attendee) => {
      if (!attendee.displayName) return;

      // only show calendar if display name is present,
      // i.e. do not attempt to show for group emails and external calendars
      addGuestCalendar(attendee.email);
    });
  }, [addGuestCalendar, attendees]);

  return (
    <Button
      className={classNames(
        `flex items-center justify-center hover:scale-105 ${
          isSmall ? `h-5 w-5` : `h-6 w-6`
        }`,
        {
          [`${colorMap.text} ${colorMap.button} hover:!bg-opacity-100 focus:!bg-opacity-100`]:
            hasFocus,
        }
      )}
      variant="popoverIcon"
      onClick={shouldShow ? resetGuestCalendars : showCalendars}
    >
      {shouldShow ? (
        <IconEyeClosed className="h-3 w-3" />
      ) : (
        <IconEyeOpened className="h-3 w-3" />
      )}
    </Button>
  );
}
