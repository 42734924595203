import { atom } from 'jotai';
import { atomFamily, useAtomValue, useUpdateAtom } from 'jotai/utils';

const selectedElementFamily = atomFamily(() => atom<HTMLElement | null>(null));

export function useEventElement(eventId: string) {
  return useAtomValue(selectedElementFamily(eventId));
}

export function useSetEventElement(eventId: string) {
  return useUpdateAtom(selectedElementFamily(eventId));
}
