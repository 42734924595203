/* 3a1fdbfeb4aa4af28040553bbc98c9d7c934c130
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SpotifyPlaySongMutationVariables = Types.Exact<{
  uri: Types.Scalars['String'];
}>;


export type SpotifyPlaySongMutation = { __typename?: 'mutation_root', spotifyPlaySong?: { __typename?: 'SpotifyPlayTrack', deviceId?: string | null, type?: string | null } | null };


export const SpotifyPlaySongDocument = gql`
    mutation SpotifyPlaySong($uri: String!) {
  spotifyPlaySong(source: "computer", uri: $uri) {
    deviceId
    type
  }
}
    `;

export function useSpotifyPlaySongMutation() {
  return Urql.useMutation<SpotifyPlaySongMutation, SpotifyPlaySongMutationVariables>(SpotifyPlaySongDocument);
};