import { DragMoveEvent } from '@dnd-kit/core';
import { useCalendarDays } from 'hooks/useCalendar';
import { DateTime } from 'luxon';
import { coordinatesToTargetDate, MouseCoordinates } from 'utils/mouseEvents';

export function getDatePoint({
  dragEvent,
  mouseCoordinates,
  startDay,
  endDay,
  roundToNearestQuarterHour = true,
}: {
  dragEvent?: DragMoveEvent | null;
  mouseCoordinates?: MouseCoordinates | null;
  startDay: DateTime;
  endDay: DateTime;
  roundToNearestQuarterHour?: boolean;
}): DateTime | null {
  if (!mouseCoordinates || !dragEvent?.over) return null;
  if (mouseCoordinates.x < 0 || mouseCoordinates.y < 0) return null;

  return coordinatesToTargetDate({
    coordinates: mouseCoordinates,
    height: dragEvent.over.rect.height,
    width: dragEvent.over.rect.width,
    roundToNearestQuarterHour,
    startDay,
    endDay,
  });
}

export function useGetDatePoint() {
  const days = useCalendarDays();
  return (
    dragEvent: DragMoveEvent | null,
    mouseCoordinates?: MouseCoordinates | null,
    roundToNearestQuarterHour?: boolean
  ) =>
    getDatePoint({
      dragEvent,
      mouseCoordinates,
      startDay: days[0].date,
      endDay: days[days.length - 1].date,
      roundToNearestQuarterHour,
    });
}
