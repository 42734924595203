import useEventNavigation from 'hooks/useEventNavigation';
import { useSetEventsSelection } from 'hooks/useEventsSelection';
import { useSetVisibleCalendars } from 'hooks/useVisibleCalendars';
import React, { useEffect } from 'react';
import { useEventsDnd } from './useEventsDnd';
import useEventShortcuts from './useEventShortcuts';

export default React.memo(function GridHandlers() {
  useEventNavigation();
  useEventShortcuts();
  useEventsDnd();

  const { clearEventsSelection } = useSetEventsSelection();
  const { resetContactsCalendars } = useSetVisibleCalendars();

  useEffect(() => {
    return () => {
      // Unset the selection when the grid is unmounted
      clearEventsSelection();
      resetContactsCalendars();
    };
  }, []); // eslint-disable-line

  return null;
});
