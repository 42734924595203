import {
  DragCancelEvent,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragStartEvent,
  useDndContext,
  useDndMonitor,
} from '@dnd-kit/core';
import { NewEventRsvpEnum } from '@graphql-types@';
import { createOrganizerAttendeeAtomCallback } from 'components/EventPopover/EventGuests/eventGuestsHelpers';
import { usePrimaryCalendarId } from 'hooks/auth/authAtoms';
import { generateEventUUID } from 'hooks/events/helpers/eventsHelpers';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useSetEventsSelection } from 'hooks/useEventsSelection';
import { useUpdateModal } from 'hooks/useModal';
import usePrevious from 'hooks/usePrevious';
import { useAtomCallback } from 'jotai/utils';
import { useCallback, useEffect, useRef } from 'react';
import { DraggableType, DroppableId } from 'types/drag-and-drop';
import { ModalType } from 'types/modal';
import { getGridMouseCoordinates } from './clickHandler/GridInteractionsHandler';
import { useGetDatePoint } from './clickHandler/helpers/dragHelpers';
import { getOneToOneEventTitle } from './utils';

export function GridContactEventGhost() {
  const { active, over } = useDndContext();
  const {
    createDraftEvent,
    deleteDraftEvent,
    updateGridEvent,
    updateGridEventForInteractionOnly,
  } = useUpdateGridEvent();
  const { openModal } = useUpdateModal();
  const { selectEvent, clearEventsSelection } = useSetEventsSelection();
  const prevOver = usePrevious(over?.id);
  const getDatePoint = useGetDatePoint();
  const primaryCalendarId = usePrimaryCalendarId();
  const draftIdRef = useRef(generateEventUUID(primaryCalendarId));

  useEffect(() => {
    if (prevOver !== DroppableId.SCHEDULE) return;

    // Handle case when dragging back to original position, off the schedule.
    // Only while dragging...
    if (prevOver && active && !over?.id) {
      deleteDraftEvent();
    }
  }, [over?.id, prevOver, deleteDraftEvent, active]);

  const onDragOver = useAtomCallback(
    useCallback(
      (get, _set, dragEvent: DragOverEvent) => {
        if (dragEvent.over?.id !== DroppableId.SCHEDULE) return;
        const current = dragEvent.active.data.current;

        if (current?.type !== DraggableType.CONTACT) return;

        const pointDate = getDatePoint(dragEvent, getGridMouseCoordinates());

        // Ignore new position if invalid or is allday
        if (!pointDate) return;

        if (current?.type === DraggableType.CONTACT) {
          draftIdRef.current = generateEventUUID(primaryCalendarId);

          const organizer = createOrganizerAttendeeAtomCallback(get);

          createDraftEvent({
            id: draftIdRef.current,
            title: getOneToOneEventTitle(organizer, current?.contact),
            startAt: pointDate,
            endAt: pointDate.plus({
              minutes: 30,
            }),
            attendees: [
              organizer,
              {
                ...current.contact,
                status: 'pending',
                RSVP: NewEventRsvpEnum.Unknown,
              },
            ],
          });
        }
      },
      [createDraftEvent, getDatePoint, primaryCalendarId]
    )
  );

  useDndMonitor({
    onDragOver,
    onDragStart: (dragEvent: DragStartEvent) => {
      if (dragEvent.active.data.current?.type === DraggableType.CONTACT) {
        deleteDraftEvent();
        clearEventsSelection();
      }
    },
    onDragMove: (dragEvent: DragMoveEvent) => {
      if (dragEvent.over?.id !== DroppableId.SCHEDULE) return;
      if (dragEvent.active.data.current?.type !== DraggableType.CONTACT) return;

      const pointDate = getDatePoint(dragEvent, getGridMouseCoordinates());

      // Ignore new position if invalid or is allday
      if (!pointDate) return;

      if (dragEvent.active.data.current?.type === DraggableType.CONTACT) {
        updateGridEventForInteractionOnly({
          id: draftIdRef.current,
          startAt: pointDate,
          endAt: pointDate.plus({
            minutes: 30,
          }),
        });
      }
    },
    onDragCancel: (event: DragCancelEvent) => {
      if (event.active.data.current?.type === DraggableType.CONTACT) {
        deleteDraftEvent();
      }
    },
    onDragEnd: async (event: DragEndEvent) => {
      if (event.active.data.current?.type === DraggableType.CONTACT) {
        selectEvent(draftIdRef.current);
        openModal(ModalType.Event);
      }
    },
  });

  return null;
}
