import { ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import IconXMark from 'components/Icons/IconXMark';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useColorHotkey } from 'hooks/useHotkey';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { useColorNicknameMap } from './Settings/rows/EventColorRename';

const containerVariant: Variants = {
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
    transition: {
      when: 'afterChildren',
      staggerChildren: 0.02,
      staggerDirection: -1,
    },
  },
  show: {
    opacity: 1,
    pointerEvents: 'all',
    transition: {
      staggerChildren: 0.02,
      delayChildren: 0.04,
    },
  },
};

const itemVariant: Variants = {
  hidden: { scale: 0, opacity: 0 },
  show: { scale: 1, opacity: 1 },
  hover: { scale: 1.1, opacity: 0 },
};

interface Props {
  value: ColorFamily;
  onChange: (value: ColorFamily) => void;
  disabled: boolean;
}

export default function ColorPicker({ value, onChange, disabled }: Props) {
  const [expanded, setExpanded] = useState(false);
  const colorItem = EVENT_COLOR_MAP[value];
  const colorNicknameMap = useColorNicknameMap();

  useColorHotkey((color) => void setExpanded(false) || onChange(color), {
    scope: 'all',
    enabled: !disabled,
  });

  return (
    <div
      className={classNames('flex', {
        'pointer-events-none': disabled,
      })}
    >
      {colorItem && (
        <Tooltip content="Edit color" targetOffset={11}>
          <Button
            data-testid={`event-color-${value}`}
            onClick={() => setExpanded((val) => !val)}
            className={classNames(
              'h-4 w-4 scale-100 transform rounded-full border-2 text-[0px] transition-transform hover:scale-110 focus:scale-110 active:scale-100',
              colorItem.colorSwatchBg,
              colorItem.border
            )}
          >
            Edit color
          </Button>
        </Tooltip>
      )}
      <AnimatePresence>
        {expanded && (
          <motion.div
            variants={containerVariant}
            initial="hidden"
            animate="show"
            exit="hidden"
            className="bg-popover absolute left-px z-10 -mt-2 max-w-md overflow-hidden rounded-2xl px-3.5 py-2"
            transition={{ duration: 0.12 }}
          >
            <div className="flex items-center justify-between gap-3">
              <motion.button
                onClick={() => setExpanded((val) => !val)}
                className="text-secondary h-4 w-4"
                variants={itemVariant}
                transition={{ duration: 0.12 }}
              >
                <IconXMark />
              </motion.button>

              <motion.span
                variants={itemVariant}
                transition={{ duration: 0.12 }}
                className="h-3 w-px rounded-full bg-gray-200 dark:bg-gray-600"
              />

              {Object.entries(EVENT_COLOR_MAP).map(
                ([colorName, colorProp], idx) => (
                  <Tooltip
                    key={colorName}
                    shortcut={`${++idx}`}
                    content={capitalize(colorNicknameMap[colorName])}
                    targetOffset={12}
                  >
                    <motion.button
                      className="relative flex items-center justify-center"
                      variants={itemVariant}
                      transition={{ duration: 0.12 }}
                      name={`color-${colorName}`}
                      onClick={() => {
                        setExpanded(false);
                        onChange(colorName as ColorFamily);
                      }}
                    >
                      <span
                        className={classNames(
                          'h-4 w-4 scale-100 transform rounded-full border-2 transition-transform hover:scale-110 active:scale-100',
                          colorProp.colorSwatchBg,
                          colorProp.border,
                          colorProp.ring,
                          {
                            'ring-2 ring-opacity-40 ring-offset-1 ring-offset-white dark:ring-offset-gray-600':
                              colorName === value,
                          }
                        )}
                      />
                    </motion.button>
                  </Tooltip>
                )
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
