import { DragMoveEvent, useDndMonitor } from '@dnd-kit/core';
import { useUpdateSelectedSlotId } from 'hooks/calendarLink/creation/useSelectedSlotId';
import { useUpdateCalendarSlot } from 'hooks/calendarLink/creation/useUpdateSlot';
import { CalendarSlotDate } from 'hooks/calendarLink/booking/atoms';
import { useRef } from 'react';
import { isSlotGhostEnabled } from './utils';
import { useGetDatePoint } from './clickHandler/helpers/dragHelpers';
import { getGridMouseCoordinates } from './clickHandler/GridInteractionsHandler';
import { MINUTES_IN_A_DAY } from 'utils/time';

export default function GridSlotGhost() {
  const { updateCalendarSlot } = useUpdateCalendarSlot();
  const updateSelectedSlotId = useUpdateSelectedSlotId();
  const beganDraggingRef = useRef(false);
  const getDatePoint = useGetDatePoint();
  const draggingOffsetRef = useRef<number | null>(null);

  useDndMonitor({
    onDragMove: async (dragEvent: DragMoveEvent) => {
      if (!isSlotGhostEnabled(dragEvent.active)) return;
      const slot: CalendarSlotDate | undefined =
        dragEvent.active.data.current?.slot;

      if (!slot) return;

      const targetDate = getDatePoint(dragEvent, getGridMouseCoordinates());

      if (!targetDate) return;

      if (draggingOffsetRef.current === null) {
        const { startAt, endAt } = slot;
        if (!startAt || !endAt) return;
        if (
          targetDate.toMillis() < startAt.toMillis() ||
          targetDate.toMillis() > endAt.toMillis()
        ) {
          draggingOffsetRef.current = endAt.diff(startAt).as('minutes') / 2;
        } else {
          draggingOffsetRef.current =
            targetDate.diff(startAt).as('minutes') % MINUTES_IN_A_DAY;
        }
      }

      if (beganDraggingRef.current === false) {
        await updateSelectedSlotId(slot.id);
      }

      beganDraggingRef.current = true;
      const draggingOffsetMinutes = draggingOffsetRef.current || 0;

      const slotDurationMinutes = slot.endAt.diff(
        slot.startAt,
        'minutes'
      ).minutes;
      const startAt = targetDate.minus({ minutes: draggingOffsetMinutes });
      const endAt = startAt.plus({ minutes: slotDurationMinutes });
      await updateCalendarSlot({ ...slot, startAt, endAt });
    },
    onDragEnd: (dragEvent: DragMoveEvent) => {
      if (!isSlotGhostEnabled(dragEvent.active)) return;
      updateSelectedSlotId(null);
      beganDraggingRef.current = false;
      draggingOffsetRef.current = null;
    },
  });

  return null;
}
