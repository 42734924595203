/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { useIsModalOpen, useUpdateModal } from 'hooks/useModal';
import { ModalType } from 'types/modal';
import dynamic from 'next/dynamic';
import FeedbackContent from './FeedbackContent';
import useHotkey from 'hooks/useHotkey';

const Modal = dynamic(() => import('components/Modal'), { ssr: false });

export default React.memo(function FeedbackPopover(): JSX.Element | null {
  const isModalOpen = useIsModalOpen(ModalType.SendFeedback);
  const { openModal, closeModal } = useUpdateModal();

  useHotkey(
    'command+f',
    {
      scope: 'all',
      enabledWithinInput: true,
      enabledWithinContentEditable: true,
    },
    (event) => {
      if (isModalOpen) {
        closeModal(ModalType.SendFeedback);
      } else {
        openModal(ModalType.SendFeedback);
      }
      event.preventDefault();
    }
  );

  return (
    <Modal id={ModalType.SendFeedback} scope="modal">
      {isModalOpen && (
        <div className="fixed top-0 left-0 z-100 flex h-full w-full items-center justify-center">
          <div
            className="z-100 flex w-full flex-col rounded-xl bg-white py-0.5 shadow-quickmenu transition-colors dark:bg-gray-750"
            style={{ width: 475, height: 260 }}
          >
            <FeedbackContent
              className="p-4"
              onSubmit={() => closeModal(ModalType.SendFeedback)}
              onCancel={() => closeModal(ModalType.SendFeedback)}
            />
          </div>
          <span
            className="fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black/75"
            onClick={() => closeModal(ModalType.SendFeedback)}
          />
        </div>
      )}
    </Modal>
  );
});
