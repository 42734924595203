import { NewEventRsvpEnum } from '@graphql-types@';
import { useUser } from 'hooks/auth/authAtoms';
import { DateTime } from 'luxon';
import { useEventsQuery } from 'graphql/queries/events.graphql';
import { useCalendarHistorySubscription } from 'graphql/subscriptions/calendarHistory.graphql';
import { noop } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { setInterval } from 'timers';
import { useCalendars } from './useCalendars';
import useElectronIpc from './useElectronIpc';
import usePrevious from './usePrevious';

function useDidDayChange() {
  const [currentTime, setCurrentTime] = useState(DateTime.now());
  const [didDayChange, setDidDayChange] = useState(false);
  const prevTime = usePrevious(currentTime);

  useEffect(() => {
    if (!prevTime) return;

    // Day of year 1-366
    if (currentTime.toFormat('dd') !== prevTime.toFormat('dd')) {
      setDidDayChange(true);
      setTimeout(() => setDidDayChange(false), 5_000);
    }
  }, [currentTime, prevTime]);

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(DateTime.now()), 10_000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return didDayChange;
}

function useElectronEvents() {
  const user = useUser();
  const userCalendar = useCalendars();
  const { ipc, isElectron } = useElectronIpc();
  const didDayChange = useDidDayChange();

  const myCalendar = useMemo(
    () => userCalendar.find((cal) => cal.isPrimary)?.id || '',
    [userCalendar]
  );
  const didEmitQuickmenuReady = useRef(false);

  const memoVars = useMemo(() => {
    return {
      variables: {
        calendarIds: [myCalendar],
        startAt: DateTime.now().minus({ minutes: 60 }).toISO(),
        endAt: DateTime.now().plus({ days: 2 }).toISO(),
      },
      pause: !user?.uid || !myCalendar.length || !isElectron,
      requestPolicy: 'cache-and-network' as const,
    };

    // Trigger new Date when didDayChange
  }, [myCalendar, user, isElectron, didDayChange]); // eslint-disable-line

  const subscriptionMemoVars = useMemo(() => {
    return {
      variables: {
        calendarIds: [myCalendar],
      },
      pause: !user?.uid || !myCalendar.length || !isElectron,
    };
  }, [myCalendar, user, isElectron]);

  const [eventsQueryRes, refetch] = useEventsQuery(memoVars);
  const [subscriptionRes] =
    useCalendarHistorySubscription(subscriptionMemoVars);
  const prevSubscriptionRef = useRef(subscriptionRes.data?.calendarHistory[0]);

  useEffect(() => {
    if (!isElectron) return noop();
    if (!ipc || !eventsQueryRes.data?.event) return noop();

    const removedDeclinedAndAllDayEvents =
      eventsQueryRes.data.event.events.filter(
        (event) => !event.isAllDay && event.rsvp !== NewEventRsvpEnum.No
      );

    ipc.send('upcoming-events', {
      events: removedDeclinedAndAllDayEvents,
    });

    if (!didEmitQuickmenuReady.current) {
      ipc.send('quickmenu-ready');
      didEmitQuickmenuReady.current = true;
    }
  }, [eventsQueryRes.data, isElectron, ipc]);

  useEffect(() => {
    /**
     * Refetch events if subscription event arrives about a calendar update.
     */
    if (!prevSubscriptionRef) return;
    if (
      prevSubscriptionRef.current?.updatedAt ===
      subscriptionRes.data?.calendarHistory[0]?.updatedAt
    )
      return;

    prevSubscriptionRef.current = subscriptionRes.data?.calendarHistory[0];
    refetch({ requestPolicy: 'cache-and-network' });
  }, [subscriptionRes.data?.calendarHistory, prevSubscriptionRef, refetch]);
}

export default useElectronEvents;

export function UseElectronEvents() {
  useElectronEvents();
  return null;
}
