import { maxNumTimezones, timezonePreferencesAtom } from 'hooks/useTimeZone';
import { useAtomValue } from 'jotai/utils';
import React from 'react';
import GridTimezoneChanger from './GridTimezoneChanger';

export default function GridTimezones() {
  const timezonePreferences = useAtomValue(timezonePreferencesAtom);

  return (
    <div className="flex w-full flex-row justify-between self-end p-0.5">
      {timezonePreferences.length < maxNumTimezones && (
        <GridTimezoneChanger timezonePreference={null} isRemoveable={true} />
      )}
      {timezonePreferences
        .map((timezonePreference, i) => {
          return (
            <GridTimezoneChanger
              key={i}
              timezonePreference={timezonePreference}
              isRemoveable={timezonePreferences.length > 1}
            />
          );
        })
        .reverse()}
    </div>
  );
}
