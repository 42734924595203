import { VideoProvider_Enum } from '@graphql-types@';
import ExternalLink from 'components/Settings/controls/ExternalLink';
import Input from 'components/Settings/controls/Input';
import RadioList from 'components/Settings/controls/RadioList';
import PageControlHeader from 'components/Settings/PageControlHeader';
import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import React, { useCallback, useState } from 'react';
import { PreferenceName, PreferenceVideoProvider } from 'types/preference';
import NextButton from './components/NextButton';
import StepHeader from './components/StepHeader';
import { OnboardingStepProps } from './OnboardingStepProps';

export default React.memo(function VideoCalls({
  onNext,
  isActive,
}: OnboardingStepProps): JSX.Element | null {
  const preferences = usePreferences();
  const updatePreference = useUpdatePreference();

  const preferenceName = PreferenceName.VideoProvider;
  const preferenceValue = preferences?.videoProvider;
  // TODO: enable when Zoom is ready
  const zoomLink = preferences?.zoomLink || '';
  const aroundLink = preferences?.aroundLink || '';

  const [localVideoProvider, setLocalVideoProvider] = useState(
    () => preferenceValue
  );

  const [localAroundLink, setLocalAroundLink] = useState(() => aroundLink);
  const [localZoomLink, setLocalZoomLink] = useState(() => zoomLink);

  const noneSelected = localVideoProvider == PreferenceVideoProvider.None;

  const save = useCallback(() => {
    if (localVideoProvider && localVideoProvider !== preferenceValue) {
      updatePreference(preferenceName, localVideoProvider);
    }
    if (localAroundLink !== aroundLink) {
      updatePreference(PreferenceName.AroundLink, localAroundLink);
    }
    onNext();
  }, [
    updatePreference,
    preferenceName,
    onNext,
    preferenceValue,
    localVideoProvider,
    localAroundLink,
    aroundLink,
  ]);

  // TODO: BE must implement wherebyLink.
  // const wherebyLink = preferences?.wherebyLink || '';

  return (
    <>
      <StepHeader
        accentColor="text-purple-400"
        title="video calls"
        primary="How do you make video calls?"
        secondary="We’ll add the right link to the events you create"
      />

      <RadioList
        items={[
          {
            title: 'Google Meet',
            description: 'Meet links will automatically be added to events',
            selected: localVideoProvider === PreferenceVideoProvider.Meet,
            onSelect: () => setLocalVideoProvider(PreferenceVideoProvider.Meet),
          },
          {
            title: 'Zoom',
            selected: localVideoProvider === PreferenceVideoProvider.Zoom,
            onSelect: () => setLocalVideoProvider(PreferenceVideoProvider.Zoom),
          },
          {
            title: 'Around',
            description: aroundLink,
            selected: localVideoProvider === PreferenceVideoProvider.Around,
            onSelect: () => {
              setLocalVideoProvider(PreferenceVideoProvider.Around);
            },
          },
        ]}
      />

      <AroundLink
        {...{ localVideoProvider, localAroundLink, setLocalAroundLink }}
      />
      <ZoomLink {...{ localVideoProvider, localZoomLink, setLocalZoomLink }} />

      <NextButton
        onNext={save}
        onSkip={onNext}
        isEnabled={isActive && !noneSelected}
        hideSkip={false}
      />
    </>
  );
});

const AroundLink = ({
  localVideoProvider,
  localAroundLink,
  setLocalAroundLink,
}: {
  localVideoProvider: VideoProvider_Enum | null | undefined;
  localAroundLink: string;
  setLocalAroundLink: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className="mt-6">
    {localVideoProvider === PreferenceVideoProvider.Around && (
      <div className="flex flex-col">
        <PageControlHeader
          title="Around link"
          description={
            <>
              Your meeting link for{' '}
              <ExternalLink href="https://www.around.co/">Around</ExternalLink>.
              It&#39;ll be available in the event location.
            </>
          }
        />

        <Input
          placeholder="e.g https://around.co/r/amie"
          value={localAroundLink}
          onSubmit={(value) => setLocalAroundLink(value)}
        />
      </div>
    )}
  </div>
);

const ZoomLink = ({
  localVideoProvider,
  localZoomLink,
  setLocalZoomLink,
}: {
  localVideoProvider: VideoProvider_Enum | null | undefined;
  localZoomLink: string;
  setLocalZoomLink: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <div className="mt-6">
    {localVideoProvider === PreferenceVideoProvider.Zoom && (
      <div className="flex flex-col">
        <PageControlHeader
          title="Zoom link"
          description={
            <>
              Your meeting link for{' '}
              <ExternalLink href="https://zoom.us/">Zoom</ExternalLink>.
              It&#39;ll be available in the event location.
            </>
          }
        />

        <Input
          placeholder="e.g https://zoom.us/r/amie"
          value={localZoomLink}
          onSubmit={(value) => setLocalZoomLink(value)}
        />
      </div>
    )}
  </div>
);
