import {
  NewEventVisibilityEnum,
  ColorFamily,
  NewEventRsvpEnum,
} from '@graphql-types@';
import React, { useCallback, useState } from 'react';
import useSpotify from 'hooks/useSpotify';
import { getDayIndex, getEventStyle, SPOTIFY_BLOCK_WIDTH } from './utils';
import { DateTime } from 'luxon';
import { useCalendarDays } from 'hooks/useCalendar';
import { omit, uniqBy } from 'lodash';
import { AnimatePresence } from 'framer-motion';
import {
  HoveredSpotifyTrack,
  SpotifyIcon,
  SpotifyTrackDots,
} from './SpotifyBarContents';

type RecentlyPlayedTrack = ReturnType<typeof useSpotify>['recentlyPlayed'][0];
const groupTracksIntoSessions = (tracks: RecentlyPlayedTrack[]) => {
  const sessions: Array<RecentlyPlayedTrack[]> = [[]];
  let sessionIndex = 0;

  const sortedTracks = uniqBy(
    tracks.sort((track1, track2) => {
      const track1Start = DateTime.fromJSDate(new Date(track1.playedAt));
      const track2Start = DateTime.fromJSDate(new Date(track2.playedAt));

      if (track1Start > track2Start) {
        return 1;
      }
      if (track1Start < track2Start) {
        return -1;
      }

      return 0;
    }),
    'playedAt'
  );

  sortedTracks.forEach((track, index, arr) => {
    if (index === tracks.length - 1) return;
    const nextTrack = arr[index + 1];

    if (nextTrack) {
      const nextTrackStart = DateTime.fromJSDate(
        new Date(arr[index + 1].playedAt)
      );
      const lastTrackStart = DateTime.fromJSDate(new Date(track.playedAt));

      const nextTrackIsWithinThirtyMinutes =
        nextTrackStart.diff(lastTrackStart, 'minutes').minutes <= 30;

      sessions[sessionIndex].push(track);

      if (!nextTrackIsWithinThirtyMinutes) {
        sessionIndex++;
        sessions.push([]);
      }
    } else {
      sessions[sessionIndex].push(track);
    }
  });

  return sessions;
};

export default React.memo(function GridSpotifyTracks(): JSX.Element {
  const { playSong, recentlyPlayed } = useSpotify();
  const days = useCalendarDays();
  const pxDiff = 2;

  const sessions = groupTracksIntoSessions(recentlyPlayed);

  const [hoveredTrackId, setHoveredTrackId] = useState('');

  const getHoveredTrackTopPos = useCallback(() => {
    if (hoveredTrackId) {
      return document.getElementById(hoveredTrackId)?.offsetTop || 0;
    }

    return 0;
  }, [hoveredTrackId]);

  const getSessionTrackFromId = useCallback(
    (session) => {
      if (hoveredTrackId) {
        return session.find(
          (track: RecentlyPlayedTrack) => track.id === hoveredTrackId
        );
      }
    },
    [hoveredTrackId]
  );

  const spotifyStyle = useCallback(
    (session: RecentlyPlayedTrack[]) => {
      if (!session.length) return undefined;

      let start = DateTime.fromJSDate(new Date(session[0].playedAt));

      // Must be at least 30 mins or it looks terrible...
      let end = DateTime.fromJSDate(
        new Date(session[session.length - 1].playedAt)
      );

      if (end.diff(start, 'minutes').minutes > 1) {
        start = DateTime.fromJSDate(new Date(session[1].playedAt));
      }

      const day = getDayIndex(days, {
        startAt: start,
      });

      // if the first or last song from that session is played more recently in a newer session,
      // the playedAt time gets set to the latest this track has played
      // therefore we need to skip it if startAt > endAt
      if (end.diff(start, 'minutes').minutes < 45) {
        end = start.plus({ minutes: 45 });
      }

      // const sessionTrack = getSessionTrackFromId(session);
      const base = omit(
        getEventStyle({
          event: {
            id: '',
            calendarId: '',
            attendees: [],
            isSelfAsAttendee: false,
            isOwnEvent: true,
            title: '',
            dayIndex: day,
            location: '',
            videoConferences: [],
            description: '',
            updatedAt: DateTime.now(),
            createdAt: start.toJSDate(),
            startAt: start,
            endAt: end,
            colorFamily: ColorFamily.Green,
            allOtherGuestsDeclined: false,
            visibility: NewEventVisibilityEnum.Default,
            canEdit: false,
            status: 'confirmed',
            belongsToUserCalendar: true,
            prevEndAt: end,
            prevStartAt: start,
            isAllDay: false,
            rsvp: NewEventRsvpEnum.Unknown,
            isDraft: false,
          },
          dayIndex: day,
        }),
        ['zIndex'] // allows us to display the spotify bar in front of sharing slot bar on hover
      );

      return {
        ...base,
        width: `${SPOTIFY_BLOCK_WIDTH}px`, // make sure it works well with slots bar when the same time period is selected
        justifySelf: 'start',
        marginLeft: pxDiff,
        backgroundColor: '#1ED761',
        padding: '16px 0 6px',
      };
    },
    [days]
  );

  return (
    <>
      {sessions.map((session, index) => {
        const hoveredTrack = getSessionTrackFromId(session);

        const activeTooltipTopPos = getHoveredTrackTopPos();

        return (
          <div
            key={'session' + index}
            role="gridcell"
            style={spotifyStyle(session)}
            className="group relative rounded-md py-1"
          >
            <AnimatePresence>
              {hoveredTrack && typeof hoveredTrack === 'object' && (
                <HoveredSpotifyTrack
                  track={hoveredTrack}
                  index={index}
                  activeTooltipTopPos={activeTooltipTopPos}
                />
              )}
            </AnimatePresence>
            <div className="relative h-full scale-100 transform transition-transform duration-75 hover:z-10">
              <div className="flex h-full flex-col items-center pt-0.5">
                <div
                  className={
                    'relative -top-4 -z-10 flex h-4 w-2.5 items-center justify-center rounded-md'
                  }
                >
                  <SpotifyIcon />
                </div>
                <SpotifyTrackDots
                  session={session}
                  setHoveredTrackId={setHoveredTrackId}
                  playSong={playSong}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
});
