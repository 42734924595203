import IconCalendar from 'components/Icons/IconCalendar';
import Button from 'joy/Button';
import Dropdown, { DropdownItem } from 'joy/Dropdown';
import Tooltip from 'joy/Tooltip';
import React, { useCallback } from 'react';
import type { IGridEvent } from 'types/events';
import type { useOwnCalendars } from 'hooks/useCalendars';
import classNames from 'classnames';

function EventCalendarPicker({
  event,
  ownCalendars,
  onChange,
}: {
  event: IGridEvent;
  ownCalendars: ReturnType<typeof useOwnCalendars>;
  onChange: (value: IGridEvent['calendarId']) => void;
}): JSX.Element {
  const getCalendarItems: () => DropdownItem[] = useCallback(
    () =>
      ownCalendars.map((calendar) => ({
        type: 'option',
        value: calendar.name,
        selected: calendar.id === event.calendarId,
        icon: calendar.emoji ? (
          <span>{calendar.emoji}</span>
        ) : (
          <IconCalendar
            className={classNames({
              [`text-${calendar.colorFamily}-400 dark:text-${calendar.colorFamily}-400`]:
                !!calendar.colorFamily,
            })}
          />
        ),
        onSelect: () => onChange(calendar.id),
      })),
    [ownCalendars, event.calendarId, onChange]
  );

  const currentCalendar = ownCalendars.find(
    (cal) => cal.id === event.calendarId
  );
  const { colorFamily, emoji, name } = currentCalendar || {};

  return (
    <Dropdown items={getCalendarItems}>
      <Tooltip content={name ? `${name} calendar` : 'Change calendar'}>
        <Button variant="popoverIcon">
          {emoji ? (
            <span>{emoji}</span>
          ) : (
            <IconCalendar
              className={classNames({
                [`text-${colorFamily}-400 dark:text-${colorFamily}-400`]:
                  !!colorFamily,
              })}
            />
          )}
        </Button>
      </Tooltip>
    </Dropdown>
  );
}

export default EventCalendarPicker;
