import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function ProfileWidgets({ children }: Props) {
  return (
    <div className="mt-4 grid grid-flow-row-dense grid-cols-2 gap-4 xl:grid-cols-3">
      {children}
    </div>
  );
}
