import {
  DndContext,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import Grid from 'components/Grid';
import LayoutLoggedIn from 'components/LayoutLoggedIn';
import Panels from 'components/Panels';
import Profile from 'components/Profile';
import QuickMenu from 'components/QuickMenu';
import Settings from 'components/Settings';
import { UseHandleAuth } from 'hooks/auth/useAuth';
import { useAtomValue } from 'jotai/utils';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { customCollisionDetectionStrategy } from 'utils/collisionDetection';
import { isProfilePage } from 'utils/router';
import { AMIE_PAGE_TITLE } from '../utils/constants';

import {
  OnboardingState,
  onboardingStepAtom,
} from 'components/Onboarding/useOnboarding';
import { OnboardingWrapper } from 'components/Onboarding/OnboardingWrapper';

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

const Index: React.FC = () => {
  const router = useRouter();
  const onboardingStep = useAtomValue(onboardingStepAtom);

  const sensors = useSensors(
    // Mouse sensor is needed for Cypress.
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  return (
    <DndContext
      id="wrapper"
      collisionDetection={customCollisionDetectionStrategy}
      sensors={sensors}
      autoScroll={true}
      modifiers={[restrictToWindowEdges]}
    >
      <Head>
        <title>{AMIE_PAGE_TITLE}</title>
        <meta name="description" content={AMIE_PAGE_TITLE} />
      </Head>
      <UseHandleAuth />

      <LayoutLoggedIn>
        {onboardingStep !== OnboardingState.USER_SETTINGS && (
          <>
            <Panels>
              {isProfilePage(router) && router.isReady && <Profile />}
              {!isProfilePage(router) && router.isReady && <Grid />}
            </Panels>
            <QuickMenu />
            <Settings />
          </>
        )}
        <OnboardingWrapper />
      </LayoutLoggedIn>
    </DndContext>
  );
};

export default Index;
