import { groupBy, sortBy } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { IGridEvent } from 'types/events';
import { diffMinutes, getTimestampInterval } from 'utils/time';

export type EventData = Pick<
  IGridEvent,
  'id' | 'startAt' | 'endAt' | 'isAllDay' | 'createdAt' | 'dayIndex'
>;

const normalizeDate = (date: DateTime | string) =>
  date instanceof DateTime ? date : DateTime.fromISO(date);

export const getEventInterval = (event: EventData): Interval => {
  return Interval.fromDateTimes(
    normalizeDate(event.startAt),
    normalizeDate(event.endAt)
  );
};

const isAllDayEvent = (event: Pick<EventData, 'isAllDay'>) => event.isAllDay;

export const getOverlappingEvents = (event: EventData, events: EventData[]) => {
  return events
    .filter((e) => {
      if (isAllDayEvent(event) && isAllDayEvent(e)) {
        return getEventInterval(e).overlaps(getEventInterval(event));
      }

      if (isAllDayEvent(event)) {
        return isAllDayEvent(e);
      }

      if (isAllDayEvent(e)) {
        return isAllDayEvent(event);
      }

      return areEventsOverlapped(event, e);
    })
    .map((e) => ({
      id: e.id,
      startAt: e.startAt,
      endAt: e.endAt,
      createdAt: e.createdAt,
      isAllDay: e.isAllDay,
      dayIndex: e.dayIndex,
    }));
};

export const findNearestParent = (event: EventData, overlaps: EventData[]) => {
  const sortedOverlaps = sortBy(overlaps, 'startAt').reverse();
  const eventLength = diffMinutes(event.endAt, event.startAt);

  let parent = null;
  let minDiff = Number.MIN_SAFE_INTEGER;

  for (const overlap of sortedOverlaps) {
    // if overlap started after event it cannot be parent
    if (overlap.startAt > event.startAt) {
      continue;
    }

    const diffStart = diffMinutes(overlap.startAt, event.startAt);
    const diffOverlap = diffMinutes(overlap.endAt, event.startAt);
    const diffEnd = diffMinutes(overlap.endAt, event.endAt);

    // if overlap has the same interval it cannot be parent
    if (diffStart === 0 && diffEnd === 0) {
      continue;
    }

    // if overlap started at the same time but ends earlier, it cannot be parent
    if (diffStart === 0 && diffOverlap < eventLength) {
      continue;
    }

    if (minDiff < diffStart && diffOverlap >= 0) {
      minDiff = diffStart;
      parent = overlap;
    }
  }

  return parent;
};

const areTwins = (event1: EventData, event2: EventData) => {
  const interval1 = getTimestampInterval(event1);
  const interval2 = getTimestampInterval(event2);

  return interval1.start === interval2.start && interval1.end === interval2.end;
};

export const areHeadersOverlapped = (event1: EventData, event2: EventData) => {
  const diff = diffMinutes(event1.startAt, event2.startAt);
  return Math.abs(diff) < 30;
};

export const areEventsOverlapped = (event1: EventData, event2: EventData) => {
  const interval1 = getTimestampInterval(event1);
  const interval2 = getTimestampInterval(event2);

  return (
    (interval1.start <= interval2.start && interval2.start < interval1.end) ||
    (interval2.start <= interval1.start && interval1.start < interval2.end)
  );
};

export const sortEvents = (a: EventData, b: EventData) => {
  const interval1 = getTimestampInterval(a);
  const interval2 = getTimestampInterval(b);

  const diffStart = interval1.start - interval2.start;
  if (diffStart !== 0) {
    return diffStart;
  }

  return interval2.end - interval1.end;
};

// Twins are events that start and end at the same time
export const findTwins = (event: EventData, overlaps: EventData[]) => {
  if (event.isAllDay) {
    return overlaps;
  }

  return overlaps.filter((overlap) => {
    return areTwins(event, overlap);
  });
};

export const findTwinIndex = (event: EventData, twins: EventData[]) => {
  return twins.findIndex((e) => e.id === event.id);
};

export const getAllOverlaps = (events: EventData[]) => {
  const eventsByDays = groupBy(events, 'dayIndex');
  const overlaps: Record<string, EventData[]> = {};

  for (const event of events) {
    const dayEvents = eventsByDays[event.dayIndex];
    overlaps[event.id] = getOverlappingEvents(event, dayEvents);
  }

  return overlaps;
};

export const excludeEvent = (events: EventData[], id: string) => {
  return events.filter((e) => e.id !== id);
};
