import classNames from 'classnames';
import { useContactsValue } from 'hooks/useContacts';
import { useFetchBesties } from 'hooks/useFetchBesties';
import useHotkey from 'hooks/useHotkey';
import useWindowResize from 'hooks/useWindowSize';
import Avatar from 'joy/Avatar';
import Button from 'joy/Button';
import React, { useEffect, useMemo, useState } from 'react';
import ColleagueButton from './components/ColleagueButton';
import ColleagueButtonSkeleton from './components/ColleagueButtonSkeleton';
import NextButton from './components/NextButton';
import StepHeader from './components/StepHeader';
import { OnboardingStepProps } from './OnboardingStepProps';

export default React.memo(function Colleagues({
  onNext,
  isActive,
  onShouldHideStep,
}: OnboardingStepProps): JSX.Element | null {
  const contacts = useContactsValue();
  const { isFetching, besties } = useFetchBesties();
  const [hasInteractedWithContacts, setHasInteractedWithContacts] =
    useState(false);
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [keyboardFocus, setKeyboardFocus] = useState<null | number>(null);
  const isMoreFocused = keyboardFocus === besties?.length;

  const bestiesWithContactInfo = useMemo(() => {
    if (isFetching || !besties) return [];

    return besties.map((bestie) => {
      const contact = contacts.find(
        (contact) => contact.emailAddress === bestie?.email
      );

      if (!contact) return null;

      return {
        ...bestie,
        contactId: contact.id,
        avatar: contact.avatar,
      };
    });
  }, [isFetching, besties, contacts]);

  const shouldHideStep = !isFetching && bestiesWithContactInfo.length === 0;

  const visibleBesties = useMemo(() => {
    if (isShowingMore) {
      return bestiesWithContactInfo;
    } else {
      return bestiesWithContactInfo.slice(0, 5);
    }
  }, [bestiesWithContactInfo, isShowingMore]);

  const isGettingBestiesInfo = useMemo(
    () => bestiesWithContactInfo.some((b) => b == null),
    [bestiesWithContactInfo]
  );

  useEffect(() => {
    if (shouldHideStep) {
      onShouldHideStep?.();
    }
  }, [bestiesWithContactInfo, onShouldHideStep, shouldHideStep]);

  const focusTargets = useMemo(() => {
    const bestiesFocusTargets = besties ? besties.map((b) => b.id) : [];
    const targets = [...bestiesFocusTargets, 'more', 'next'];

    return targets;
  }, [besties]);

  const isFocusedTarget = (target: string) => {
    return focusTargets.indexOf(target) === keyboardFocus;
  };

  useHotkey(
    'right',
    {
      enabled: isActive,
      scope: 'onboarding',
    },
    () => {
      if (keyboardFocus === null) {
        setKeyboardFocus(0);
      } else if (keyboardFocus < focusTargets.length - 1) {
        setKeyboardFocus(keyboardFocus + 1);
      }
    },
    [isActive, keyboardFocus, setKeyboardFocus]
  );

  useHotkey(
    'left',
    {
      enabled: isActive,
      scope: 'onboarding',
    },
    () => {
      if (keyboardFocus === null || keyboardFocus === 0) {
        setKeyboardFocus(0);
      } else {
        setKeyboardFocus(keyboardFocus - 1);
      }
    },
    [isActive, keyboardFocus, setKeyboardFocus]
  );

  useHotkey(
    'enter',
    {
      enabled: isActive,
      scope: 'onboarding',
    },
    () => {
      if (isMoreFocused) {
        setIsShowingMore(true);
      }
    }
  );

  return (
    <>
      <StepHeader
        accentColor="text-orange-500"
        title="colleagues"
        primary="Who do you work closest with?"
        secondary="Based on your recent events, here’s who we suggest"
      />
      {isFetching || isGettingBestiesInfo ? (
        <div className="mt-1 -ml-1 flex flex-row flex-wrap">
          <ColleagueButtonSkeleton width={280} />
          <ColleagueButtonSkeleton width={180} />
          <ColleagueButtonSkeleton width={160} />
          <ColleagueButtonSkeleton width={180} />
          <ColleagueButtonSkeleton width={240} />
          <ColleagueButtonSkeleton width={260} />
          <ColleagueButtonSkeleton width={220} />
          <ColleagueButtonSkeleton width={280} />
        </div>
      ) : (
        <>
          <div className="mt-1 -ml-1 flex flex-row flex-wrap">
            {visibleBesties.map((bestie, i) => (
              <ColleagueButton
                isKeyboardFocused={keyboardFocus === i}
                key={i}
                contactId={bestie?.contactId}
                onSelect={() => setHasInteractedWithContacts(true)}
                disabled={!isActive}
              >
                {/* TODO: Include the avatars - but what strategy do we want to use?
                  We potentially have a 4 step waterfall
                  - Their avatar in Amie system (if they're signed up already)
                  - Their photo from your contacts (directory listing)
                  - Photo from 'other contacts' `https://people.googleapis.com/v1/otherContacts:search?query=${email}&readMask=names,emailAddresses`
                  - `https://www.google.com/m8/feeds/photos/media/${callerEmail}/${data.results[0].person.names[0].metadata.source.id}`
                  Or 5 if you want to generate some cool random avatar image - otherwise if your contacts aren't on gmail, you will have
                  X number of blank avatars which is pretty boring.
                */}
                <Avatar
                  size={24}
                  name={bestie?.displayName || ''}
                  src={bestie?.avatar}
                />
                <span className="ml-2">
                  {bestie?.displayName || bestie?.email}
                </span>
              </ColleagueButton>
            ))}
            <div className="">
              {bestiesWithContactInfo.length > 5 && !isShowingMore && (
                <Button
                  onClick={() => setIsShowingMore(true)}
                  className={classNames(
                    'm-1.5 flex cursor-pointer items-center rounded-md p-3 font-semibold hover:bg-gray-200/60 dark:text-gray-100 dark:hover:bg-gray-700',
                    {
                      'bg-gray-100 dark:bg-gray-700/60': !isMoreFocused,
                      'scale-105 bg-gray-200 dark:bg-gray-700': isMoreFocused,
                    }
                  )}
                >
                  More...
                </Button>
              )}
            </div>
          </div>

          <NextButton
            onNext={onNext}
            isEnabled={isActive && hasInteractedWithContacts}
            hideSkip={hasInteractedWithContacts}
            isFocused={isFocusedTarget('next')}
          />
        </>
      )}
    </>
  );
});
