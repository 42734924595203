import React from 'react';
import FollowToggle from 'components/FollowToggle';
import { useFavoriteContact } from 'hooks/useFavoriteContact';

interface Props {
  id: string;
  compact?: boolean;
}

export default React.memo(function PopoverFollowToggle({ id }: Props) {
  const { isFavorite, toggle } = useFavoriteContact(id);

  return <FollowToggle isFollowing={isFavorite} handleClick={toggle} />;
});
