import { ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import { useUser } from 'hooks/auth/authAtoms';
import { useWorkerContext } from 'contexts/worker';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useContactsValue } from 'hooks/useContacts';
import { useSetEventsSelection } from 'hooks/useEventsSelection';
import { useUpdateModal } from 'hooks/useModal';
import ComboBox, { ComboBoxActions, ComboBoxItem } from 'joy/ComboBox';
import React, { useCallback, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { IGridEvent } from 'types/events';
import { TEST_ID_POPOVER_EVENT_TITLE } from 'utils/constants';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { NEW_EVENT_PLACEHOLDER_TITLE } from 'utils/events';

interface Props {
  eventId: IGridEvent['id'];
  autoFocus?: boolean;
  onPressEscape?: () => void;
  titleGroupFocused: boolean;
  setTitleGroupFocused: React.Dispatch<React.SetStateAction<boolean>>;
  readOnly: boolean;
  value: IGridEvent['title'];
  onChange: (value: IGridEvent['title']) => void;
  colorFamily?: ColorFamily;
}

/**
 * TODO: Bring back when emoji picker + ComboBox can work together
 *
 * const TextareaAutosizeWithEmojiPicker = withEmojiPicker(TextareaAutosize);
 */

export function EventTitle({
  autoFocus,
  value,
  eventId,
  onChange,
  onPressEscape,
  readOnly,
  colorFamily = ColorFamily.Gray,
  titleGroupFocused,
  setTitleGroupFocused,
}: Props): React.ReactElement {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const contacts = useContactsValue();
  const user = useUser();
  const { closeModal } = useUpdateModal();
  const { updateGridEvent } = useUpdateGridEvent();
  const { clearEventsSelection } = useSetEventsSelection();
  const { handleEventsSearch, eventResults } = useWorkerContext();
  const [isComboBoxActive, setIsComboBoxActive] = React.useState(false);

  const colorMap = EVENT_COLOR_MAP[colorFamily];

  const onItemSelect = useCallback(
    (_, item: ComboBoxItem | undefined, actions: ComboBoxActions) => {
      if (!item || item.type !== 'option') return;

      actions.setOpen(false);
      inputRef.current?.blur();
      /**
       * Fill in known details from selected event...
       */
      const result = eventResults.find((event) => event?.title === item.value);
      if (!result) return;
      const {
        description,
        videoConferences,
        colorFamily,
        title,
        visibility,
        location,
      } = result;

      const attendees =
        result.attendees.length === 1 &&
        result.attendees[0].email === user?.email
          ? []
          : result.attendees;

      const eventLocation =
        videoConferences.length &&
        videoConferences[0].provider === 'GOOGLE_MEET'
          ? videoConferences[0].link
          : location;

      updateGridEvent({
        id: eventId,
        title,
        description,
        attendees,
        videoConferences,
        location: eventLocation,
        colorFamily,
        visibility,
      });
    },
    [eventId, eventResults, updateGridEvent, user?.email]
  );

  const getAvatarUrl = (attendee: IGridEvent['attendees'][0]) => {
    if (attendee.email === user?.email) {
      return user?.photo || '';
    }

    return (
      contacts.find((contact) => contact.emailAddress === attendee.email)
        ?.avatar || ''
    );
  };

  const getAutoCompleteItems = (): ComboBoxItem[] => [
    { type: 'title', value: 'Copy from history' },
    ...eventResults.map((event) => ({
      type: 'option' as const,
      value: event?.title || '',
      avatarGroup: event?.attendees
        .filter((attendee, _index, arr) => {
          // When i am the only attendee, don't show avatar
          if (arr.length === 1 && attendee.email === user?.email) {
            return false;
          }
          return true;
        })
        .map((attendee) => ({
          /**
           * Avatar not available from events cached in worker,
           * so search through contacts for their avatar..
           */
          url: getAvatarUrl(attendee),
          text: attendee.email,
        })),
    })),
  ];

  return (
    <ComboBox
      className="flex flex-grow"
      items={getAutoCompleteItems}
      onSubmit={onItemSelect}
      onInputChange={(e) => {
        handleEventsSearch(e);
        return onChange(e);
      }}
      onInputSubmit={closeModal}
      inputRef={inputRef}
      offset={8}
      hideWhenEmpty={true}
      onHighlightChange={(index) => {
        if (index === -1) {
          setIsComboBoxActive(false);
        } else {
          setIsComboBoxActive(true);
        }
      }}
    >
      <TextareaAutosize
        ref={inputRef}
        data-testid={TEST_ID_POPOVER_EVENT_TITLE}
        // Only autoFocus empty input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus && value.length === 0}
        spellCheck={false}
        disabled={readOnly}
        placeholder={NEW_EVENT_PLACEHOLDER_TITLE}
        onFocus={(event) => {
          // Put cursor at last position
          event.target.setSelectionRange(value.length, value.length);
          setTitleGroupFocused(true);
        }}
        onBlur={() => setTitleGroupFocused(false)}
        value={value}
        className={classNames(
          'peer w-full resize-none bg-transparent text-lg font-bold leading-6 tracking-tight outline-none transition-colors',
          colorMap.selection,
          {
            [`${colorMap.text} ${colorMap.placeholder}`]: titleGroupFocused,
          }
        )}
        onKeyDown={(event) => {
          // if no value has been entered and escape is pressed skip field blur and close the popover
          if (event.key === 'Escape' && !value) {
            event.stopPropagation();
            onPressEscape?.();
          }

          if (event.key === 'Enter' && !isComboBoxActive && value) {
            clearEventsSelection();
          }
        }}
        // TODO: Bring back when emoji picker is wrapping TextareaAutosize again...
        // onPressEnter={() => {
        //   blurInput();
        //   onPressEnter?.();
        // }}
        // onValueChange={onChange}
      />
    </ComboBox>
  );
}
