import classNames from 'classnames';
import React from 'react';

interface Props {
  children: React.ReactNode;
  clickable?: boolean;
  span?: 1 | 2;
}

export default function ProfileWidget({
  children,
  clickable,
  span = 1,
}: Props) {
  return (
    <div
      className={classNames(
        'relative h-[272px] rounded-3xl bg-white shadow-feint dark:bg-gray-750',
        {
          'transition-transform hover:scale-102 active:scale-100': clickable,
        }
      )}
      style={{ gridColumn: `span ${span}` }}
    >
      {children}
    </div>
  );
}
