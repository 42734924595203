import { useUpdateContactByPkMutation } from 'graphql/mutations/UpdateContact.graphql';
import {
  ContactFavoriteAtQueryVariables,
  useContactFavoriteAtQuery,
} from 'graphql/queries/contacts.graphql';
import { atom } from 'jotai';
import {
  atomFamily,
  useAtomCallback,
  useAtomValue,
  useUpdateAtom,
} from 'jotai/utils';
import React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import type { UseQueryArgs } from 'urql';
import useProfile from './useProfile';
import { useSetVisibleCalendars } from './useVisibleCalendars';

type QueryType = Omit<UseQueryArgs<ContactFavoriteAtQueryVariables>, 'query'>;

interface Props {
  email: string;
}

const profileFollowFamily = atomFamily(() => atom<boolean>(false));

export default function useProfileFollow(contactId: string): boolean {
  return useAtomValue(profileFollowFamily(contactId));
}

export function useProfileFollowUpdate(
  contactId: string,
  email: string
): {
  toggleFollowing: () => Promise<void>;
} {
  const [, updateContact] = useUpdateContactByPkMutation();
  const { removeCalendar } = useSetVisibleCalendars();

  const toggleFollowing = useAtomCallback(
    useCallback(
      async (get, set) => {
        if (contactId === '') return;

        const isFollowing = get(profileFollowFamily(contactId));
        set(profileFollowFamily(contactId), !isFollowing);
        removeCalendar(email);

        await updateContact({
          id: contactId,
          _set: { favoriteAt: isFollowing ? null : new Date().toISOString() },
        });
      },
      [contactId, removeCalendar, email, updateContact]
    )
  );

  return { toggleFollowing };
}

export const SyncProfileFollow = React.memo(function SyncProfileFollow({
  email,
}: Props): null {
  const profile = useProfile(email);
  const contactId = profile?.contactId;
  const updateProfileFollow = useUpdateAtom(profileFollowFamily(contactId));

  const query = useMemo<QueryType>(
    () =>
      contactId
        ? {
            variables: { id: contactId },
            context: {
              requestPolicy: 'cache-and-network' as const,
              suspense: true,
            },
          }
        : { pause: true },
    [contactId]
  );

  const [queryResults] = useContactFavoriteAtQuery(query);

  useEffect(() => {
    if (!queryResults?.data?.new_contact_by_pk) return;

    updateProfileFollow(
      typeof queryResults.data?.new_contact_by_pk?.favoriteAt === 'string'
    );
  }, [queryResults.data?.new_contact_by_pk, updateProfileFollow]);

  return null;
});
