import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import NextButton from './components/NextButton';
import StepHeader from './components/StepHeader';
import Tooltip from 'joy/Tooltip';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import useHotkey, { useColorHotkey } from 'hooks/useHotkey';
import { OnboardingStepProps } from './OnboardingStepProps';
import { capitalize } from 'lodash';
import { useUpdateCalendarMutation } from 'graphql/mutations/UpdateCalendar.graphql';
import { ColorFamily } from '@graphql-types@';
import { useUserEmail } from 'hooks/auth/authAtoms';
import { useOwnCalendarListQuery } from 'graphql/queries/OwnCalendarList.graphql';

export default React.memo(function DefaultEventColor({
  onNext,
  isActive,
}: OnboardingStepProps): JSX.Element | null {
  const colorButtonRefs = useRef<(HTMLElement | null)[]>([]);
  const canChangeColors = useRef(true);
  const userEmail = useUserEmail();
  /**
   * Call this hook directly or else calendar list is delayed
   * until all events are loaded in SyncCalendars.
   */
  const [ownCalendarsResults] = useOwnCalendarListQuery({
    pause: !userEmail,
  });
  const primaryCalendar = ownCalendarsResults.data?.ownCalendarList[0];
  const [, updateCalendar] = useUpdateCalendarMutation();
  const [optimisticCalendar, setOptimisticCalendar] = useState(
    () => primaryCalendar
  );

  const [hasInteracted, setInteracted] = useState(false);

  useEffect(() => {
    if (primaryCalendar) {
      setOptimisticCalendar(primaryCalendar);
    }
  }, [primaryCalendar]);

  const selectedColorIndex = useMemo(
    () =>
      Object.entries(EVENT_COLOR_MAP).findIndex(
        ([id]) => id === optimisticCalendar?.colorFamily
      ),
    [optimisticCalendar]
  );
  useEffect(() => {
    canChangeColors.current = isActive;
  }, [isActive]);

  const optimisticUpdateColor = useCallback(
    (color: string) => {
      setInteracted(true);
      setOptimisticCalendar((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          colorFamily: color as ColorFamily,
        };
      });
    },
    [setOptimisticCalendar, setInteracted]
  );

  const saveColorOnServer = useCallback(
    (color: string) => {
      if (!userEmail) return undefined;

      updateCalendar({
        id: primaryCalendar?.id || userEmail,
        colorFamily: color as ColorFamily,
      });
    },
    [updateCalendar, primaryCalendar, userEmail]
  );

  useColorHotkey(
    (color) => canChangeColors.current && optimisticUpdateColor(color),

    'all'
  );

  useEffect(() => {
    if (colorButtonRefs.current[selectedColorIndex]) {
      colorButtonRefs.current[selectedColorIndex]?.focus({
        preventScroll: true,
      });
    }
  }, [selectedColorIndex]);

  useHotkey(
    'left',
    { scope: 'onboarding', enabled: isActive },
    () => {
      if (selectedColorIndex > 0) {
        const selectedColorEntry =
          Object.entries(EVENT_COLOR_MAP)[selectedColorIndex - 1];
        const [key] = selectedColorEntry;

        optimisticUpdateColor(key);
      }
    },
    [isActive, selectedColorIndex, optimisticUpdateColor]
  );

  useHotkey(
    'right',
    { scope: 'onboarding', enabled: isActive },
    () => {
      if (selectedColorIndex < Object.entries(EVENT_COLOR_MAP).length - 1) {
        const selectedColorEntry =
          Object.entries(EVENT_COLOR_MAP)[selectedColorIndex + 1];
        const [key] = selectedColorEntry;

        optimisticUpdateColor(key);
      }
    },
    [isActive, selectedColorIndex]
  );

  useEffect(() => {
    if (hasInteracted && !isActive && optimisticCalendar) {
      saveColorOnServer(optimisticCalendar.colorFamily);
    }
  }, [
    hasInteracted,
    isActive,
    saveColorOnServer,
    optimisticCalendar,
    primaryCalendar,
  ]);

  return (
    <>
      <StepHeader
        accentColor="text-blue-400"
        title="event color"
        primary="What color do you want new events to be?"
        secondary="You can change this later in Settings, for each calendar"
      />

      <div className="flex justify-between">
        {Object.entries(EVENT_COLOR_MAP).map(([id, colorProp], i: number) => (
          <Tooltip
            key={id}
            content={capitalize(id)}
            shortcut={`${i + 1}`}
            ref={(ref) => (colorButtonRefs.current[i] = ref)}
            disabled={!isActive}
          >
            <button
              className={classNames(
                'relative flex items-center justify-center transition-all',
                {
                  'hover:scale-105 focus:scale-105 active:scale-100': isActive,
                }
              )}
              name={`color-${id}`}
              onClick={() => {
                optimisticUpdateColor(id);
              }}
              tabIndex={isActive ? 0 : -1}
              disabled={!isActive || !primaryCalendar || !primaryCalendar.id}
            >
              <span
                className={classNames(
                  'h-14 w-14 transform rounded-full border-8 capitalize',
                  'transition-all',
                  colorProp.colorSwatchBg,
                  colorProp.border,
                  colorProp.ring,
                  {
                    'border-1  ring-4 ring-opacity-40 ring-offset-4 ring-offset-white dark:ring-offset-gray-800 md:ring-offset-2':
                      id === optimisticCalendar?.colorFamily,
                  }
                )}
              />
            </button>
          </Tooltip>
        ))}
      </div>

      <NextButton onNext={onNext} isEnabled={isActive} />
    </>
  );
});
