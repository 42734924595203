import sortBy from 'lodash/sortBy';
import { diffMinutes } from 'utils/time';
import {
  areHeadersOverlapped,
  EventData,
  excludeEvent,
  findTwinIndex,
  findTwins,
} from './overlap';

interface IOverlapInfo {
  twins: number;
  index: number;
  overlapsParentTitle: boolean;
  withinParent: boolean;
  headerOverlaps: EventData[];
  parentIsHalf?: boolean;
}

type SerializedOverlapInfo = {
  twins: number;
  index: number;
  overlapsParentTitle: boolean;
  withinParent: boolean;
  headerOverlaps: string[];
  parentIsHalf?: boolean;
};

export interface OptimizedEventOverlap {
  parentId?: string;
  level: number;
  index: number;
  overlaps?: string[];
  isAllDay?: boolean;
  isLastChild?: boolean;

  resetLevel: boolean;
  globalLevel: number;
  info: SerializedOverlapInfo;
  prevInfo?: SerializedOverlapInfo;
  tree: {
    depth: number;
    titleDepth: number;
    titleLevel: number;
    rootId?: string;
  };
}

class OverlapNode {
  id: string;

  private _children: OverlapNode[] = [];
  private _overlaps: EventData[] = [];
  private _parent?: OverlapNode;
  private _optimized = false;
  private _globalLevel = 0;
  private _prevInfo?: IOverlapInfo;
  private _prevParent?: OverlapNode;

  constructor(readonly data: EventData, parent?: OverlapNode) {
    this.id = data.id;
    this._parent = parent;
  }

  get parent(): OverlapNode | undefined {
    return this._parent;
  }

  get level(): number {
    return this.parent ? this.parent.level + 1 : 0;
  }

  get globalLevel(): number {
    return this._globalLevel;
  }

  get root(): OverlapNode {
    return this._parent?.root ?? this;
  }

  get children(): OverlapNode[] {
    return this._children;
  }

  get overlaps(): EventData[] {
    return this._overlaps;
  }

  get siblings(): OverlapNode[] {
    return this.parent?.children ?? [];
  }

  get optimized(): boolean {
    return this._optimized;
  }

  get depth(): number {
    return OverlapNode.getDepth(this.root);
  }

  get prevParent(): OverlapNode | undefined {
    return this._prevParent;
  }

  get info(): IOverlapInfo {
    const event = this.data;
    const overlaps = excludeEvent(this._overlaps, this.id);
    const twins = findTwins(event, this._overlaps);
    const index = findTwinIndex(event, twins);

    const info: IOverlapInfo = {
      twins: twins.length - 1,
      index,
      overlapsParentTitle: false,
      parentIsHalf: this.parent?.info.overlapsParentTitle,
      withinParent: false,
      headerOverlaps: overlaps.filter((overlap) =>
        areHeadersOverlapped(event, overlap)
      ),
    };

    const parentEvent = this.parent?.data;
    if (parentEvent) {
      info.withinParent = diffMinutes(event.endAt, parentEvent.endAt) <= 0;
      info.overlapsParentTitle =
        diffMinutes(event.startAt, parentEvent.startAt) < 30;
    }

    return info;
  }

  static getDepth(node: OverlapNode): number {
    const maxChildDepth = node.children.reduce(
      (max, child) => Math.max(max, OverlapNode.getDepth(child)),
      0
    );

    return 1 + maxChildDepth;
  }

  static getTitleDepth(node: OverlapNode | null): number {
    if (!node) {
      return 0;
    }

    const maxTitleDepth = node.children.reduce((max, child) => {
      if (!child.info.overlapsParentTitle) {
        return max;
      }
      return Math.max(max, OverlapNode.getTitleDepth(child));
    }, 0);

    return 1 + maxTitleDepth;
  }

  static findFirstNonTitleOverlap(node: OverlapNode) {
    let current = node.info.overlapsParentTitle ? node : null;

    while (current) {
      const parent = current?.parent;
      if (!parent) {
        return current;
      } else {
        current = parent;
      }

      if (!current.info.overlapsParentTitle) {
        return current;
      }
    }

    return current;
  }

  plain() {
    const info = this.info;
    const titleOverlapRoot = OverlapNode.findFirstNonTitleOverlap(this);
    const titleRootTwins = titleOverlapRoot?.info.twins ?? 0;
    const titleDepth =
      OverlapNode.getTitleDepth(titleOverlapRoot) +
      (titleOverlapRoot?.info.twins ?? 0);

    return {
      index: info.index,
      isAllDay: this.data.isAllDay,
      isLastChild: false,
      level: this.level,
      overlaps: this.overlaps.map((e) => e.id),
      parentId: this.parent?.id,
      resetLevel: this.optimized,
      globalLevel: this.globalLevel,
      tree: {
        titleDepth: titleDepth > 0 ? Math.max(2, titleDepth) : 0,
        titleLevel: titleOverlapRoot
          ? this.level - titleOverlapRoot.level + titleRootTwins
          : 0,
        depth: this.root?.depth ?? 1,
        rootId: this.root?.id,
      },
      info: this.plainInfo(info) as SerializedOverlapInfo,
      prevInfo: this.plainInfo(this._prevInfo),
    };
  }

  private plainInfo(info?: IOverlapInfo) {
    if (!info) {
      return;
    }

    return {
      index: info.index,
      overlapsParentTitle: info.overlapsParentTitle,
      headerOverlaps: info.headerOverlaps.map((e) => e.id),
      twins: info.twins,
      withinParent: info.withinParent,
      parentIsHalf: info.parentIsHalf,
    };
  }

  debug(): any {
    return {
      id: this.id,
      depth: this.depth,
      level: this.level,
      children: this.children.map((c) => c.id),
      parent: this.parent?.id,
      root: this.root.id,
      optimized: this.optimized,
    };
  }

  isLastChild(): boolean {
    if (!this.parent) {
      return false;
    }

    const siblings = sortBy(this.parent.children, 'data.startAt');
    const lastSibling = siblings[siblings.length - 1];

    return lastSibling.id === this.id;
  }

  isOverlapping(node?: OverlapNode): boolean {
    if (!node) {
      return false;
    }
    return this.overlaps.find((o) => o.id === node.id) !== null;
  }

  // when undefined - moves to the tree root
  moveTo(parent: OverlapNode | undefined): void {
    this._prevInfo = this.info;
    this._prevParent = this.parent;

    this.parent?.removeChild(this);

    this._parent = parent;
    this._optimized = true;
  }

  removeChild(node: OverlapNode): void {
    this._children = this._children.filter((child) => child.id !== node.id);
  }

  addChild(data: EventData): OverlapNode {
    const node = new OverlapNode(data, this);
    this.children.push(node);
    return node;
  }

  setOverlaps(overlaps: EventData[]): void {
    this._overlaps = overlaps;
  }

  setGlobalLevel(level: number): void {
    this._globalLevel = level;
  }
}

export default OverlapNode;
