import {
  useCalendarLink,
  useOneOffUnbookedCalendarLinkIds,
} from 'hooks/calendarLink/creation/useCalendarLink';
import { useSelectedCalendarLinkId } from 'hooks/calendarLink/creation/useSelectedCalendarLink';
import { CalendarMode } from 'hooks/useCalendarMode';
import useTimezone from 'hooks/useTimeZone';
import React from 'react';
import { DraggableType } from 'types/drag-and-drop';
import { IGridDay } from '../../types/events';
import GridSlotGhost from './GridSlotGhost';
import GridSlotWrapper from './GridSlotWrapper';

interface Props {
  days: IGridDay[];
  calendarMode: CalendarMode;
}

export default React.memo(function GridCalendarLinkContent(props: Props) {
  const selectedCalendarLinkId = useSelectedCalendarLinkId();

  return (
    <>
      {props.calendarMode === 'selecting-recurring-slots' && (
        <ShareLinkRecurringSlots {...props} />
      )}
      {props.calendarMode !== 'default' && selectedCalendarLinkId && (
        <ShareLinkSlots {...props} calendarLinkId={selectedCalendarLinkId} />
      )}

      <ShareLinkOneOffSlots {...props} />
    </>
  );
});

function ShareLinkRecurringSlots({
  days,
  calendarMode,
}: Props): JSX.Element | null {
  const selectedCalendarLinkId = useSelectedCalendarLinkId();
  if (!selectedCalendarLinkId) return null;

  return (
    <ShareLinkSlots
      calendarLinkId={selectedCalendarLinkId}
      calendarMode={calendarMode}
      days={days}
    />
  );
}

function ShareLinkOneOffSlots({
  days,
  calendarMode,
}: Props): JSX.Element | null {
  const oneOffCalendarLinkIds = useOneOffUnbookedCalendarLinkIds();
  if (!oneOffCalendarLinkIds) return null;

  return (
    <>
      {oneOffCalendarLinkIds.map((calendarLinkId, index) => (
        <ShareLinkSlots
          key={`${index}-${calendarLinkId}`}
          calendarLinkId={calendarLinkId}
          calendarMode={calendarMode}
          days={days}
        />
      ))}
    </>
  );
}

function ShareLinkSlots({
  calendarLinkId,
  days,
}: Props & { calendarLinkId: string }): JSX.Element | null {
  const calendarLink = useCalendarLink(calendarLinkId);
  const selectedCalendarLinkId = useSelectedCalendarLinkId();

  const timezone = useTimezone();
  if (!calendarLink) return null;

  return (
    <>
      {calendarLink?.slots.map((slot, index) => (
        <GridSlotWrapper
          key={`${index}-${slot.id}`}
          tooltip={calendarLink.title}
          days={days}
          slot={slot}
          timezone={timezone}
          type={DraggableType.SLOT}
          disabled={
            selectedCalendarLinkId !== null &&
            selectedCalendarLinkId !== calendarLink.id
          }
        />
      ))}
      <GridSlotGhost />
    </>
  );
}
