import { useEffect } from 'react';
import { PreferenceVideoProvider } from 'types/preference';
import { usePreferences } from 'hooks/usePreferences';
import type { IGridEvent } from 'types/events';

type UseLocationFillerParams = {
  location: IGridEvent['location'];
  setLocation: (location: IGridEvent['location']) => void;
  dependencies: {
    attendees: IGridEvent['attendees'];
  };
  options: {
    disabled: boolean;
  };
};

const useLocationAutoFiller = ({
  location,
  setLocation,
  dependencies: { attendees },
  options: { disabled },
}: UseLocationFillerParams): void => {
  const { videoProvider, aroundLink } = usePreferences();
  const callLink =
    videoProvider === PreferenceVideoProvider.Around ? aroundLink : undefined;

  useEffect(() => {
    if (
      !disabled &&
      attendees.length &&
      videoProvider !== PreferenceVideoProvider.Meet &&
      callLink &&
      location === '' &&
      location !== callLink
    ) {
      setLocation(callLink);
    }
  }, [
    attendees.length,
    callLink,
    videoProvider,
    disabled,
    location,
    setLocation,
  ]);
};

export default useLocationAutoFiller;
