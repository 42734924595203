import IconCheck from 'components/Icons/IconCheck';
import IconCheckCircle from 'components/Icons/IconCheckCircle';
import IconQuestionMarkCircle from 'components/Icons/IconQuestionMarkCircle';
import { AnimatePresence, motion } from 'framer-motion';
import { useContact, useContactUpdate } from 'hooks/useContacts';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import React, { useEffect, useRef, useState } from 'react';

interface Props {
  email: string;
}

export default React.memo(function WidgetNote({ email }: Props) {
  const [saved, setSaved] = useState(false);
  const contact = useContact(email);
  const updateContact = useContactUpdate(email);
  const [noteValue, setNoteValue] = useState(contact?.notes || '');
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const limitLines = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.keyCode !== 13) return;

    event.preventDefault();
    save();
  };

  const save = () => {
    updateContact({ notes: textareaRef.current?.value || '' });
    if (textareaRef.current?.value !== contact?.notes) setSaved(true);
  };

  useEffect(() => {
    if (typeof contact?.notes !== 'string') return;
    setNoteValue(contact?.notes);
  }, [contact?.notes]);

  useEffect(() => {
    if (!saved) return;
    setTimeout(() => setSaved(false), 3000);
  }, [saved]);

  return (
    <Button
      as="div"
      onClick={() => textareaRef.current?.focus()}
      className="relative flex h-full w-full cursor-pointer flex-col overflow-hidden rounded-3xl bg-gradient-to-t from-yellow-300 to-yellow-200 text-white dark:from-yellow-500 dark:to-yellow-400"
    >
      <div className="flex w-full flex-col">
        <div className="flex h-12 w-full items-center justify-center bg-white/80">
          <AnimatePresence initial={false}>
            {saved ? (
              <motion.div
                key="saved"
                initial={{ y: 4, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.16 } }}
                exit={{ y: 4, opacity: 0 }}
                className="absolute flex items-center gap-1"
                transition={{ duration: 0.12 }}
              >
                <small className="text-sm font-black uppercase leading-snug tracking-wider text-yellow-800/50">
                  Saved
                </small>
                <IconCheckCircle className="h-4 w-4 text-yellow-800/30" />
              </motion.div>
            ) : (
              <motion.div
                key="notes"
                initial={{ y: -4, opacity: 0 }}
                animate={{ y: 0, opacity: 1, transition: { delay: 0.16 } }}
                exit={{ y: -4, opacity: 0 }}
                className="flex items-center gap-1"
                transition={{ duration: 0.12 }}
              >
                <small className="text-sm font-black uppercase leading-snug tracking-wider text-yellow-800/50">
                  Note
                </small>
                <Tooltip content="Notes are only visible to you">
                  <small className="text-yellow-800/30">
                    <IconQuestionMarkCircle className="h-4 w-4" />
                  </small>
                </Tooltip>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="h-0.5 w-full bg-black/5" />
        <div className="mt-3 h-0.5 w-full bg-black/5" />
      </div>

      <div className="flex w-full flex-grow">
        <textarea
          ref={textareaRef}
          className="resize-none border-0 bg-transparent px-6 py-4 text-xl text-yellow-800 outline-none selection:bg-yellow-800/20 placeholder:text-yellow-800/50"
          onChange={(event) => setNoteValue(event.target.value)}
          onKeyDown={limitLines}
          onBlur={save}
          spellCheck={false}
          placeholder="Keep a note for yourself"
          maxLength={120}
          value={noteValue}
          wrap="hard"
        />
      </div>
    </Button>
  );
});
