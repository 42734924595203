import { useUserEmail } from 'hooks/auth/authAtoms';
import { useFetchBestiesQuery } from 'graphql/queries/profile.graphql';
import { useMemo } from 'react';

export function useFetchBesties() {
  const userEmail = useUserEmail();

  const options = useMemo(
    () => ({
      variables: {
        domainEndsWith: `${userEmail?.split('@')[1]}`,
      },
      context: {
        requestPolicy: 'cache-first' as const,
        suspense: true,
      },
    }),
    [userEmail]
  );

  const [query, refetch] = useFetchBestiesQuery(options);

  return {
    isFetching: query.fetching,
    besties: query.data?.bestie,
    error: query.error,
    refetch,
  };
}
