import { ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import { useEventCardFocusContext } from 'contexts/eventCardFocus';
import { SetStateAction } from 'jotai';
import React from 'react';
import { Attendee } from 'types/events';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import EventAttendee from './EventAttendee';
import MoreAttendeesBubble from './MoreAttendeesBubble';

interface Props {
  title: string;
  items: Attendee[];
  orientation: 'horizontal' | 'vertical';
  colorFamily: ColorFamily;
  readOnly?: boolean;
  setExpanded: (update: SetStateAction<boolean>) => void | Promise<void>;
  deleteAttendee: (attendeeId: string, attendeeEmail: string) => void;
}

const maxNumHorizontalAttendees = 7;

export default function EventGuestList({
  title,
  items,
  orientation,
  colorFamily,
  readOnly,
  setExpanded,
  deleteAttendee,
}: Props) {
  const { hasFocus } = useEventCardFocusContext();
  const colorMap = EVENT_COLOR_MAP[colorFamily];

  if (items.length === 0) return null;

  if (orientation === 'horizontal') {
    return (
      <div className="flex flex-col gap-1.5">
        {!readOnly && (
          <small
            className={classNames(
              'text-[11px] font-black uppercase leading-snug tracking-wider transition-colors',
              {
                [colorMap.text]: hasFocus,
                'text-gray-400': !hasFocus,
              }
            )}
          >
            {title}
          </small>
        )}

        <div className="flex gap-1.5">
          {items.slice(0, maxNumHorizontalAttendees).map((guest) => (
            <EventAttendee
              key={`temp-guest-${guest.id}`}
              attendee={guest}
              expanded={false}
              deleteAttendee={deleteAttendee}
              readOnly={readOnly}
            />
          ))}

          {items.length > maxNumHorizontalAttendees && (
            <MoreAttendeesBubble
              attendees={items.slice(maxNumHorizontalAttendees)}
              cardColorMap={colorMap}
              onClick={() => setExpanded((val) => !val)}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-1.5">
      {!readOnly && (
        <small
          className={classNames(
            'text-[11px] font-black uppercase leading-snug tracking-wider transition-colors',
            {
              [colorMap.text]: hasFocus,
              'text-gray-400': !hasFocus,
            }
          )}
        >
          {title}
        </small>
      )}

      {items.map((guest) => (
        <EventAttendee
          key={`temp-guest-${guest.id}`}
          attendee={guest}
          expanded={orientation === 'vertical'}
          deleteAttendee={deleteAttendee}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
}
