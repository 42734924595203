import {
  ProfileMeetInfoQueryVariables,
  useProfileMeetInfoQuery,
} from 'graphql/queries/profile.graphql';
import { atom } from 'jotai';
import { atomFamily, useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useEffect, useMemo } from 'react';
import { TProfileMeetInfo } from 'types/profile';
import type { UseQueryArgs } from 'urql';

type QueryType = Omit<UseQueryArgs<ProfileMeetInfoQueryVariables>, 'query'>;
interface Props {
  email: string;
}

const profileMeetInfoFamily = atomFamily(() =>
  atom<TProfileMeetInfo | null>(null)
);

export default function useProfileMeetInfo(
  email: string
): TProfileMeetInfo | null {
  return useAtomValue(profileMeetInfoFamily(email));
}

export function SyncProfileMeetInfo({ email }: Props): null {
  const updateProfile = useUpdateAtom(profileMeetInfoFamily(email));

  const options = useMemo<QueryType>(
    () =>
      email
        ? {
            variables: { email },
            context: {
              requestPolicy: 'cache-and-network' as const,
              suspense: true,
            },
          }
        : { pause: true },
    [email]
  );
  const [queryResults] = useProfileMeetInfoQuery(options);

  useEffect(() => {
    if (!queryResults?.data?.profileMeetInfo) return;
    updateProfile(queryResults.data.profileMeetInfo);
  }, [queryResults.data?.profileMeetInfo, updateProfile]);

  return null;
}
