import IconTwitter from 'components/Settings/icons/IconTwitter';
import React from 'react';
import WidgetTwitterEmpty from './WidgetTwitterEmpty';

interface Props {
  firstName: string | null;
  isSelf: boolean;
  handle: string | undefined;
}

export default React.memo(function WidgetTwitter({
  handle,
  firstName,
  isSelf,
}: Props) {
  if (!handle)
    return <WidgetTwitterEmpty firstName={firstName} isSelf={isSelf} />;

  return (
    <a
      href={`https://twitter.com/${handle}`}
      rel="noopener noreferrer"
      target="_blank"
      className="flex flex-col w-full h-full p-6 text-white bg-gradient-to-t dark:bg-gradient-to-b from-twitter to-twitter/70 rounded-3xl"
    >
      <div className="flex justify-between w-full">
        <IconTwitter className="w-10 h-10" />
      </div>

      <div className="flex flex-col w-full mt-auto">
        <small className="text-sm font-black tracking-wider uppercase text-white/60 mb-0.5">
          Twitter
        </small>

        <p className="text-xl font-black">@{handle}</p>
      </div>
    </a>
  );
});
