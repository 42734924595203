import IconCake from 'components/Icons/IconCake';
import IconMapPin from 'components/Icons/IconMapPin';
import IconTwitter from 'components/Settings/icons/IconTwitter';
import { DateTime } from 'luxon';
import React from 'react';

interface Props {
  twitter?: string;
  location?: string;
  birthday?: string | null;
}

export default function ProfileHeaderInfo({
  twitter,
  location,
  birthday,
}: Props) {
  if (!twitter && !location && !birthday) return null;

  return (
    <div className="flex gap-4 mt-5">
      {twitter && (
        <div className="flex gap-1.5 items-center text-secondary">
          <div className="relative flex opacity-60 top-px">
            <IconTwitter />
          </div>
          <p className="text-sm font-semibold">@{twitter}</p>
        </div>
      )}
      {location && (
        <div className="flex gap-1.5 items-center text-secondary">
          <div className="flex opacity-60">
            <IconMapPin />
          </div>
          <p className="text-sm font-semibold">{location}</p>
        </div>
      )}
      {birthday && (
        <div className="flex gap-1.5 items-center text-secondary">
          <div className="flex opacity-60">
            <IconCake />
          </div>
          <p className="text-sm font-semibold">
            {DateTime.fromISO(birthday).toFormat('MMM d')}
          </p>
        </div>
      )}
    </div>
  );
}
