import { atom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { IGridEvent } from 'types/events';
import {
  alldayVisibleServerEvents,
  gridEventsFamily,
  visibleEventsAtom,
  visibleEventsIdsAtom,
} from './eventAtoms';
import { getDurationDays } from 'components/Grid/utils';
import { useCallback } from 'react';
import { isMultiDay } from 'components/Grid/utils';

export function useAllDayEventsIds(): string[] {
  return useAtomValue(allDayEventIdsAtom);
}

const allDayEventsPositionsAtom = atom((get) => {
  return get(alldayVisibleServerEvents)
    .map((event) => ({
      ...event,
      weekLength: getDurationDays(event) > 7 ? 7 : getDurationDays(event),
    }))
    .sort((a, b) => b.weekLength - a.weekLength);
});

const stackedEventsAtom = atom<{ [eventId: string]: number }>((get) => {
  const events = get(allDayEventsPositionsAtom);
  return new Array(7)
    .fill([])
    .map((_, index) => {
      return events
        .filter((event) => {
          const spanDays = event.endAt.diff(event.startAt, 'days').days;

          return (
            index + 1 >= event.startAt.weekday &&
            index + 1 <= event.startAt.weekday + spanDays
          );
        })
        .map((event, index) => {
          return {
            ...event,
            position: index + 1,
          };
        });
    })
    .reduce((acc, day) => {
      return [...acc, ...day];
    }, [])
    .reduce((acc, event, _index, arr) => {
      return {
        ...acc,
        [event.id]: Math.max(
          ...arr.filter((e) => e.id === event.id).map((e) => e.position)
        ),
      };
    }, {});
});

export function useAllDayRowsPosition() {
  const stackedEvents = useAtomValue(stackedEventsAtom);

  const getRowPosition = useCallback(
    (eventId: string) => {
      return stackedEvents[eventId] || 1;
    },
    [stackedEvents]
  );

  return {
    getRowPosition,
  };
}

export function useAllDayRows(): number {
  return useAtomValue(allDayRowsAtom);
}

export function isAllDayEvent(event?: IGridEvent | null) {
  return event?.isAllDay || isMultiDay(event);
}

const allDayEventIdsAtom = atom((get) => {
  return get(visibleEventsIdsAtom).filter((eventId) =>
    isAllDayEvent(get(gridEventsFamily(eventId)))
  );
});

const allDayRowsAtom = atom((get) => {
  const eventsPos = Object.values(get(stackedEventsAtom));
  const maxRows = eventsPos.length > 0 ? Math.max(...eventsPos) : 1;
  return maxRows;
});
