import { useWorkerContext } from 'contexts/worker';
import React from 'react';

function EventsSearchPrecache() {
  const { handlePrecache, handleEventsSearch } = useWorkerContext();

  React.useEffect(() => {
    /**
     * This kicks off the Web Worker to fetch events to be searched
     * in the background, when filling in an event title.
     */
    window.requestIdleCallback(() => handlePrecache());
  }, [handlePrecache, handleEventsSearch]);

  return null;
}

export default EventsSearchPrecache;
