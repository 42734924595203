import { Loader } from 'joy/Loader';
import React from 'react';

interface Props {
  width?: number;
}

export default React.memo(function ColleagueButtonSkeleton({
  width = 200,
}: Props) {
  return (
    <Loader width={width} height={52} className={'mb-2 mr-3'}>
      <rect y={5} rx={5} ry={5} width="100%" height={48} />
    </Loader>
  );
});
