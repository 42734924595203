/* 1ac4c0a97510957d05a7dc89954676c7f7c8ba96
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SpotifyRecentlyPlayedQueryVariables = Types.Exact<{
  date: Types.Scalars['DateTimeRFC3339'];
}>;


export type SpotifyRecentlyPlayedQuery = { __typename?: 'query_root', spotifyRecentlyPlayed?: { __typename?: 'SpotifyQueue', tracks: Array<{ __typename?: 'RecentlyPlayedTrack', album?: string | null, artist: string, duration: number, id: string, photo?: string | null, playedAt: any, title: string, uri: string }> } | null };


export const SpotifyRecentlyPlayedDocument = gql`
    query SpotifyRecentlyPlayed($date: DateTimeRFC3339!) {
  spotifyRecentlyPlayed(before: $date) {
    tracks {
      album
      artist
      duration
      id
      photo
      playedAt
      title
      uri
    }
  }
}
    `;

export function useSpotifyRecentlyPlayedQuery(options: Omit<Urql.UseQueryArgs<SpotifyRecentlyPlayedQueryVariables>, 'query'>) {
  return Urql.useQuery<SpotifyRecentlyPlayedQuery>({ query: SpotifyRecentlyPlayedDocument, ...options });
};