import { useUpdateEffect } from '@react-aria/utils';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useEventElement } from 'hooks/useEventElement';
import {
  eventsSelectionAtom,
  useEventsSelection,
} from 'hooks/useEventsSelection';
import { modalAtom, useUpdateModal } from 'hooks/useModal';
import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import { ModalType } from 'types/modal';
import PopoverLayout from './PopoverLayout';
import usePrevious from 'hooks/usePrevious';
import {
  guestCalendarsAtom,
  hideOwnCalendarAtom,
} from 'hooks/useVisibleCalendars';
import dynamic from 'next/dynamic';
import { useDndContext } from '@dnd-kit/core';
import { useAtomCallback, useAtomValue } from 'jotai/utils';

const Modal = dynamic(() => import('components/Modal'), { ssr: false });

export default React.memo(function Popover(): JSX.Element | null {
  const modal = useAtomValue(modalAtom);
  const { openModal, closeModal } = useUpdateModal();
  const { active } = useDndContext();

  const isDragging = active?.data?.current?.type === 'EVENT';

  const temporarilyHidden = isDragging;

  const eventsSelection = useEventsSelection();
  const { deleteDraftEvent, saveGridEvent } = useUpdateGridEvent();

  const eventId = eventsSelection[0];

  const prevSelectionLength = usePrevious(eventsSelection.length);

  const anchorElement = useEventElement(eventId);
  const isModalOpen = useMemo(
    () => modal === ModalType.QuickMenu || modal === ModalType.Event,
    [modal]
  );
  const wasModalOpen = usePrevious(isModalOpen);

  // When the popover closes, it's our duty to create or save the event,
  // but oftentimes we are getting closed by clearing selected events,
  // instead of directly calling CloseModal. Keeping track of the last selected event's id
  // is more resilient than remembering to use CloseModal everywhere we use clearEventsSelection,
  // whilst also protecting us against other race conditions that could happen.
  const lastSelectedEventId = useRef<string | undefined>(eventId);

  const updateOrSaveEvent = useCallback(
    (eventId) => {
      if (!eventId) return;
      saveGridEvent({ id: eventId, forceSave: true });
      lastSelectedEventId.current = undefined;
    },
    [saveGridEvent]
  );

  useEffect(() => {
    if (lastSelectedEventId.current === eventId) return;

    updateOrSaveEvent(lastSelectedEventId.current);

    if (eventId !== undefined) {
      lastSelectedEventId.current = eventId;
    }
  }, [eventId, updateOrSaveEvent]);

  useUpdateEffect(() => {
    if (isModalOpen && !eventId) closeModal(ModalType.Event);
  }, [closeModal, openModal, eventId]);

  const handleClosePopover = useAtomCallback(
    useCallback(
      (get, set) => {
        set(hideOwnCalendarAtom, false);
        set(guestCalendarsAtom, []);

        if (get(eventsSelectionAtom).length <= 1) {
          set(eventsSelectionAtom, []);
        }

        deleteDraftEvent();
      },
      [deleteDraftEvent]
    )
  );

  useEffect(() => {
    if (wasModalOpen && !isModalOpen && !temporarilyHidden) {
      handleClosePopover();
    }
  }, [prevSelectionLength, handleClosePopover, isModalOpen, temporarilyHidden, wasModalOpen]);

  return (
    <Modal id={ModalType.Event} scope="modal">
      {anchorElement && !temporarilyHidden && (
        <PopoverLayout
          eventId={eventId}
          isOpen={isModalOpen}
          anchorElement={anchorElement}
        />
      )}
    </Modal>
  );
});
