import classNames from 'classnames';
import CalendarButton from 'components/Panels/CalendarButton';
import {
  getAmieEndText,
  getAmieMeetingStatus,
  getAmieStartText,
  getAmieStatusTitle,
  getDurationProgress,
} from 'components/Status/utils';
import { placeholderEvent } from 'components/UserPopover/AmieIntegrationStatus';
import { motion } from 'framer-motion';
import { useSingleEventQuery } from 'graphql/queries/singleEvent.graphql';
import useUserStatus from 'hooks/useUserStatus';
import { useSetVisibleCalendars } from 'hooks/useVisibleCalendars';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

interface Props {
  email: string;
  showViewCalendar: boolean;
}

export default React.memo(function WidgetMeeting({
  email,
  showViewCalendar,
}: Props) {
  const { push } = useRouter();
  const { addCalendar } = useSetVisibleCalendars();
  const status = useUserStatus({ userEmail: email });
  const [queryResults] = useSingleEventQuery({
    variables: {
      eventId: status.integrationAmieEventId || '',
      calendarId: status.userEmail,
    },
    requestPolicy: 'cache-and-network',
    pause:
      status.integrationAmieEventId === undefined ||
      status.integrationAmieEventId === 'placeholder',
  });

  const event =
    status.integrationAmieEventId === 'placeholder'
      ? placeholderEvent
      : queryResults.data?.singleEvent;
  const eventTitle = queryResults.fetching ? undefined : event?.title || 'Busy';

  const meetingStatus = getAmieMeetingStatus(
    status,
    (event?.attendees.length ?? 1) > 1
  );

  const viewCalendar = useCallback(() => {
    push('/');
    addCalendar(email);
  }, [addCalendar, email, push]);

  if (!meetingStatus || meetingStatus === 'ended') {
    return null;
  }

  return (
    <div className="flex h-full w-full flex-col rounded-3xl bg-gradient-to-t from-white to-white p-6 dark:bg-gradient-to-b dark:from-gray-600/10 dark:to-gray-800/40">
      {showViewCalendar && (
        <Tooltip content="Show calendar" targetOffset={12}>
          <Button className="w-max" onClick={viewCalendar}>
            <CalendarButton />
          </Button>
        </Tooltip>
      )}

      <div className="mt-auto flex flex-col">
        <small
          className={classNames(
            'mb-0.5 text-sm font-black uppercase leading-snug tracking-wider',
            {
              'text-green-400 dark:text-green-200': meetingStatus === 'planned',
              'text-red-400 dark:text-red-200': meetingStatus !== 'planned',
            }
          )}
        >
          {getAmieStatusTitle(meetingStatus)}
        </small>
        <p className="text-xl font-black line-clamp-2">{eventTitle}</p>

        <div className="relative mt-4 flex">
          {status.integrationAmieStartAt &&
            status.integrationAmieEndAt &&
            meetingStatus !== 'planned' && (
              <>
                <div className="h-2 w-full rounded-full bg-gray-200 dark:bg-gray-700" />
                <div
                  className="absolute h-2 w-full rounded-full bg-red-400/80 dark:bg-red-400/80"
                  style={{
                    width: getDurationProgress(
                      status.integrationAmieStartAt,
                      status.integrationAmieEndAt
                    ),
                  }}
                />
              </>
            )}

          {meetingStatus === 'planned' && (
            <div className="relative mb-1.5 w-full">
              <div className="absolute inset-0 flex w-full justify-between">
                {Array(12)
                  .fill(null)
                  .map((_, index) => (
                    <div
                      key={index}
                      className="h-2 w-2 rounded-full bg-green-400/40 dark:bg-green-300/30"
                    />
                  ))}
              </div>

              <div className="flex w-full justify-between">
                {Array(12)
                  .fill(null)
                  .map((_, index) => (
                    <motion.div
                      key={index}
                      className="h-2 w-2 rounded-full bg-green-400 dark:bg-green-300/80"
                      animate={{
                        opacity: [0, 1],
                        transition: {
                          repeat: Infinity,
                          repeatType: 'reverse',
                          delay: 0.1 * index,
                        },
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>

        <div className="mt-2.5 flex">
          <p className="text-secondary text-base font-medium">
            {meetingStatus === 'planned'
              ? getAmieStartText(status.integrationAmieStartAt)
              : getAmieEndText(status.integrationAmieEndAt)}
          </p>
        </div>
      </div>
    </div>
  );
});
