import useCalendar from 'hooks/useCalendar';
import useCurrentTime from 'hooks/useCurrentTime';
import { useUi24HourClock } from 'hooks/usePreferences';
import React from 'react';
import { IGridDay } from 'types/events';
import { getTimeFormat } from 'utils/format';
import { isSameDay } from 'utils/time';
import { gridPosition } from './utils';

interface Props {
  days: IGridDay[];
}

export default React.memo(function GridCurrentTime({
  days,
}: Props): JSX.Element | null {
  const currentTime = useCurrentTime();
  const { startDate, endDate } = useCalendar();
  const ui24HourClock = useUi24HourClock();

  const isCurrentWeek =
    currentTime && currentTime > startDate && currentTime < endDate;

  if (!currentTime || !isCurrentWeek) return null;

  const dayIndex = days.findIndex((day) => isSameDay(day.date, currentTime));
  const hour = currentTime.hour;
  const minute = currentTime.minute;

  const row = gridPosition.rowStart + hour;
  const rowOffset = `calc(100% * ${minute / 60})`;

  return (
    <>
      <div
        className="pointer-events-none relative z-10 flex select-none justify-end pr-1.5"
        style={{
          gridRow: row,
          gridColumn: gridPosition.labels,
          top: rowOffset,
        }}
      >
        <span className="absolute -top-5 right-0 z-0 h-11 w-12  bg-gradient-to-b from-gray-50/0 via-gray-50 dark:from-gray-800/0 dark:via-gray-800" />

        <span className="relative -top-1.5 z-10 flex text-xs font-semibold text-rose-500 dark:text-rose-400">
          {currentTime
            .toFormat(getTimeFormat(ui24HourClock).long)
            .toLowerCase()}
        </span>
      </div>

      <div
        className="bg-background pointer-events-none relative z-20 h-0.5 select-none rounded"
        style={{
          gridRow: row,
          gridColumn: `${gridPosition.columnStart} / -1`,
          top: rowOffset,
        }}
      >
        <div className="absolute left-0 right-0 h-0.5 rounded bg-rose-500/[15%]" />
      </div>

      {dayIndex >= 0 && (
        <>
          <div
            className="bg-background pointer-events-none relative z-20 h-0.5 select-none rounded"
            style={{
              gridRow: row,
              gridColumn: gridPosition.columnStart + dayIndex + 1,
              top: rowOffset,
            }}
          >
            <div className="absolute left-0 right-0 h-0.5 rounded-full bg-rose-500" />
          </div>

          <div
            className="pointer-events-none relative z-20 select-none"
            style={{
              gridRow: row,
              gridColumn: `${gridPosition.columnStart + dayIndex + 1} / -1`,
              top: rowOffset,
            }}
          >
            <span className="border-background -mt-[7px] -ml-[2.5px] flex h-4 w-1.5 rounded-full border-2 bg-rose-500" />
          </div>
        </>
      )}
    </>
  );
});
