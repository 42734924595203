import React from 'react';
import { IconSVGProps } from 'types/icon';

const IconXMark: React.FC<IconSVGProps> = ({ height, width, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill="currentColor"
        d="M2.43 10.244a.793.793 0 00.006 1.096c.304.299.814.293 1.095.012L7 7.882l3.463 3.464a.787.787 0 001.096-.006.787.787 0 00.006-1.096L8.102 6.781l3.463-3.468a.782.782 0 00-.006-1.096.787.787 0 00-1.096-.006L7 5.674 3.531 2.21a.787.787 0 00-1.095.006c-.3.299-.293.814-.006 1.095l3.463 3.47-3.463 3.462z"
      ></path>
    </svg>
  );
};

export default IconXMark;
