import { ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import IconCheckboxMark from 'components/Icons/IconCheckboxMark';
import Button from 'joy/Button';
import React from 'react';
import { IGridEvent } from 'types/events';
import { EVENT_COLOR_MAP } from 'utils/eventColors';

type Props = {
  value: IGridEvent['doneAt'];
  colorFamily?: ColorFamily;
  titleGroupFocused: boolean;
  onChange: (value: IGridEvent['doneAt']) => void;
};
const EventDone: React.FC<Props> = ({
  value,
  colorFamily = ColorFamily.Gray,
  titleGroupFocused,
  onChange,
}) => {
  const colorMap = EVENT_COLOR_MAP[colorFamily];

  return (
    <Button
      onClick={() => {
        onChange(value ? null : new Date().toISOString());
      }}
      className={classNames(
        'mr-1.5 mt-0.5 flex h-5 w-5 items-center justify-center rounded-lg border-2',
        {
          [colorMap.checkbox]: !value,
          [colorMap.checkboxFocused]: titleGroupFocused && !value,
          [colorMap.checkboxChecked]: value,
        }
      )}
    >
      <IconCheckboxMark />
    </Button>
  );
};

export default EventDone;
