import {
  CollisionDetection,
  rectIntersection,
  ClientRect,
} from '@dnd-kit/core';
import { DroppableId } from 'types/drag-and-drop';

function maxX(r1: ClientRect) {
  return r1.left + r1.width;
}

function maxY(r1: ClientRect) {
  return r1.top + r1.height;
}

function doRectsIntersects(r1: ClientRect, r2: ClientRect) {
  return !(
    r2.left >= maxX(r1) ||
    maxX(r2) <= r1.left ||
    r2.top >= maxY(r1) ||
    maxY(r2) <= r1.top
  );
}

export const customCollisionDetectionStrategy: CollisionDetection = (data) => {
  const { droppableContainers: rects, collisionRect } = data;
  const scheduleContainer = rects.find(
    (rect) => rect.id === DroppableId.SCHEDULE
  );
  const scheduleRect = scheduleContainer?.rect.current;

  if (!scheduleContainer || !scheduleRect) return rectIntersection(data);

  if (doRectsIntersects(collisionRect, scheduleRect))
    return [{ id: DroppableId.SCHEDULE }];

  return rectIntersection(data);
};
