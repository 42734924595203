import { ColorFamily } from '@graphql-types@';
import IconLockedClosed from 'components/Icons/IconLockClosed';
import React from 'react';
import EventCard from './EventCard';
import useProfile from 'hooks/useProfile';

interface EventPrivacyMaskProps {
  eventCalendarId: string;
  colorFamily: ColorFamily;
}

export default function EventPrivacyMask({
  eventCalendarId,
  colorFamily,
}: EventPrivacyMaskProps): React.ReactElement {
  const eventOwner = useProfile(eventCalendarId || null);
  const privacyCopy = eventOwner?.firstName
    ? `${eventOwner?.firstName}'s`
    : `This`;

  return (
    <EventCard
      key="privateEvent"
      title=""
      colorFamily={colorFamily}
      readOnly={true}
    >
      <div className={'flex items-center justify-center text-sm text-gray-400'}>
        <IconLockedClosed className="mr-2 h-4 w-4 " />
        {`${privacyCopy} event is private`}
      </div>
    </EventCard>
  );
}
