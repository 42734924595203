import Popover from 'components/EventPopover';
import EventsSearchPrecache from 'components/EventsSearchPrecache';
import FeedbackPopover from 'components/Feedback/FeedbackPopover';
import { IntercomWidget } from 'components/intercom/IntercomWidget';
import { WorkerProvider } from 'contexts/worker';
import { SyncDnd } from 'hooks/drag/useDrag';
import { SyncOverlapInfo } from 'hooks/events/useEventOverlap';
import useElectronIpc from 'hooks/useElectronIpc';
import { UseElectronEvents } from 'hooks/useElectronEvents';
import { FetchCurrentWeekFromCache } from 'hooks/useFetchCurrentWeekFromCache';
import React from 'react';
import GridHandlers from './GridHandlers';
import GridWrapper from './GridWrapper';
import { SubscribeForCalendarsUpdates } from './SubscribeForCalendarsUpdates';

export default React.memo(function Grid(): JSX.Element {
  const { isElectron } = useElectronIpc();
  return (
    <WorkerProvider>
      {isElectron && <UseElectronEvents />}
      <FetchCurrentWeekFromCache />
      <GridWrapper />
      <GridHandlers />
      <Popover />
      <FeedbackPopover />
      <SubscribeForCalendarsUpdates />
      <EventsSearchPrecache />
      <IntercomWidget />
      <SyncOverlapInfo />
      <SyncDnd />
    </WorkerProvider>
  );
});
