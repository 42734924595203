import { DragEndEvent, DragStartEvent, useDndMonitor } from '@dnd-kit/core';
import {
  clearDragAtom,
  dragAtom,
  dragEventAtom,
} from 'components/Todos/todosAtoms';
import { useAltKey } from 'hooks/useHotkey';
import { useAtomCallback, useUpdateAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { DraggableType, DroppableId } from 'types/drag-and-drop';

export function useEventsDnd(): void {
  const altPressed = useAltKey();
  const handleGridDragStart = useAtomCallback(
    useCallback(
      (_, set, event: DragStartEvent) => {
        if (altPressed) return;
        const activeData = event.active.data.current;
        if (activeData == null) return;
        if (
          activeData.type === DraggableType.EVENT &&
          activeData.event.title?.length > 0
        ) {
          set(dragAtom, { type: DraggableType.EVENT, id: activeData.event.id });
          set(dragEventAtom, activeData.event);
        }
      },
      [altPressed]
    )
  );
  const clearDragAtoms = useUpdateAtom(clearDragAtom);

  const handleGridDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (event.over?.id !== DroppableId.SCHEDULE) return;
      clearDragAtoms();
    },
    [clearDragAtoms]
  );

  useDndMonitor({
    onDragEnd: handleGridDragEnd,
    onDragStart: handleGridDragStart,
  });
}
