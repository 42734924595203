import React from 'react';
import NextButton from './components/NextButton';
import StepHeader from './components/StepHeader';
import { OnboardingStepProps } from './OnboardingStepProps';
import { CategoryUpsertInput } from '@graphql-types@';
import { atom } from 'jotai';
import { DownloadDesktopCard } from 'components/Settings/rows/DownloadDesktopCard';
import { DownloadMobileCard } from 'components/Settings/rows/DownloadMobileCard';

export const todoCategoriesOnboardAtom = atom<CategoryUpsertInput[]>([]);

export default React.memo(function OnboardingDownloadApp({
  onNext,
  isActive,
}: OnboardingStepProps): JSX.Element | null {
  return (
    <>
      <StepHeader
        accentColor="text-pink-200"
        title="Download"
        primary="Amie for desktop and mobile"
        secondary="You can find this later in Settings"
      />
      <div className="space-y-4">
        <DownloadDesktopCard />
        <DownloadMobileCard />
      </div>

      <NextButton
        onNext={onNext}
        isEnabled={isActive}
        hideSkip={true}
        textPrimary={'Finish'}
      />
    </>
  );
});
