import React from 'react';
import classNames from 'classnames';

interface Props {
  title: string;
  primary: string;
  secondary?: string;
  accentColor: string;
}

export default React.memo(function StepHeader({
  title,
  primary,
  secondary,
  accentColor,
}: Props): JSX.Element {
  return React.useMemo(
    () => (
      <div className="mb-8">
        <div
          className={classNames(
            accentColor,
            'self-center text-xs font-black uppercase tracking-wider'
          )}
        >
          {title}
        </div>
        <div className="mt-2 text-xl font-semibold text-gray-900 dark:text-white">
          {primary}
        </div>
        {secondary && (
          <div className="mt-1 text-base font-normal text-gray-600 dark:text-gray-400">
            {secondary}
          </div>
        )}
      </div>
    ),
    [title, primary, secondary, accentColor]
  );
});
