import React, { useCallback, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import OnboardingSteps from './OnboardingSteps';
import { useHotkeyContext } from 'contexts/hotkey';
import { useUpdatePreference } from 'hooks/usePreferences';
import { PreferenceName } from 'types/preference';
import { useRouter } from 'next/router';
import { useAtom } from 'jotai';
import { OnboardingState, onboardingStepAtom } from './useOnboarding';

export default function Onboarding(): JSX.Element | null {
  const router = useRouter();
  const { pathname, query, replace } = router;
  const [onboardingStep, setOnboardingStep] = useAtom(onboardingStepAtom);

  const onboardingFadeDurationSeconds = 0.5;
  const updatePreference = useUpdatePreference();

  const { setScope } = useHotkeyContext();

  const removeOnboardingFlag = useCallback(() => {
    const newQuery = Object.assign({}, query);
    delete newQuery.onboard;

    replace(
      {
        pathname: pathname,
        query: newQuery,
      },
      undefined,
      {
        shallow: true,
      }
    );
  }, [query, pathname, replace]);

  const onFinish = useCallback(() => {
    setOnboardingStep(OnboardingState.FINISH_STEP);
    updatePreference(PreferenceName.HasOnboarded, true);
    setTimeout(() => {
      setOnboardingStep(OnboardingState.NOT_ONBOARDING);
      removeOnboardingFlag();
    }, onboardingFadeDurationSeconds * 1000);
  }, [updatePreference, setOnboardingStep, removeOnboardingFlag]);

  useEffect(() => {
    if (onboardingStep === OnboardingState.USER_SETTINGS) window.scrollY = 0;
  }, [onboardingStep]);

  useEffect(() => {
    if (onboardingStep === OnboardingState.USER_SETTINGS) {
      setScope('onboarding');
    }
  }, [setScope, onboardingStep]);

  useEffect(() => {
    const addScroll = () => document.body.classList.add('overflow-hidden');
    const removeScroll = () => document.body.classList.add('overflow-hidden');
    if (onboardingStep === OnboardingState.USER_SETTINGS) removeScroll();
    if (onboardingStep === OnboardingState.NOT_ONBOARDING) addScroll();

    return () => {
      addScroll();
    };
  }, [onboardingStep]);

  useEffect(() => {
    if (query.onboard === 'true') {
      setOnboardingStep(OnboardingState.USER_SETTINGS);
    }
  }, [setOnboardingStep, query?.onboard]);

  return (
    <AnimatePresence>
      {onboardingStep !== OnboardingState.NOT_ONBOARDING &&
        onboardingStep === OnboardingState.USER_SETTINGS && (
          <OnboardingSteps onFinish={onFinish} />
        )}
    </AnimatePresence>
  );
}
