import { atom } from 'jotai';
import {
  atomFamily,
  selectAtom,
  useAtomValue,
  useUpdateAtom,
} from 'jotai/utils';
import { useEffect } from 'react';
import { visibleServerEventsAtom } from './eventAtoms';
import OverlapStyler, {
  DEFAULT_GRID_EVENT_WIDTH,
  StylesOverride,
} from './overlap/OverlapStyler';

export type EventOverlapStyles = {
  index?: number;
  styles: {
    width: string;
    marginLeft: string;
    zIndex: number;
  };
};

const overlapInfoAtom = atom<OverlapStyler | null>(null);

export function SyncOverlapInfo() {
  const visibleEvents = useAtomValue(visibleServerEventsAtom);
  const setOverlapInfo = useUpdateAtom(overlapInfoAtom);

  useEffect(() => {
    const overlapInfo = new OverlapStyler(visibleEvents);
    setOverlapInfo(overlapInfo);
  }, [visibleEvents, setOverlapInfo]);

  return null;
}

export function useEventOverlap(eventId: string): StylesOverride {
  return useAtomValue(overlappingEventsFamily(eventId));
}

const overlappingEventsFamily = atomFamily((eventId: string) =>
  selectAtom(
    atom((get): StylesOverride => {
      const styler = get(overlapInfoAtom);
      return (
        styler?.get(eventId) || {
          index: 0,
          styles: {
            width: DEFAULT_GRID_EVENT_WIDTH,
            marginLeft: '0px',
            zIndex: 0,
          },
        }
      );
    }),
    (style) => style,
    (a, b) =>
      a.styles.marginLeft === b.styles.marginLeft &&
      a.styles.width === b.styles.width &&
      a.styles.zIndex === b.styles.zIndex
  )
);
