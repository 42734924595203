import { ColorFamily } from '@graphql-types@';
import React from 'react';
import EventCard from '../EventCard';
import LocationInput from './LocationInput';
interface Props {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  onSave?: () => void;
  readOnly: boolean;
  colorFamily: ColorFamily;
  eventId: string;
  isDraft?: boolean;
}

export default function EventLocation(props: Props): React.ReactElement {
  return (
    <EventCard
      title={'Where'}
      colorFamily={props.colorFamily}
      readOnly={props.readOnly}
    >
      {({ hasFocus }) => <LocationInput {...props} hasFocus={hasFocus} />}
    </EventCard>
  );
}
