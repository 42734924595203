import { useTodosDroppable } from 'components/Todos/todos-dnd';
import { useCalendarDays } from 'hooks/useCalendar';
import { useCalendarModeValue } from 'hooks/useCalendarMode';
import { useCursorStyle } from 'hooks/useCursor';
import { timezonePreferencesAtom } from 'hooks/useTimeZone';
import { useAtomValue } from 'jotai/utils';
import React, { useRef } from 'react';
import { DroppableId } from 'types/drag-and-drop';
import { TEST_ID_GRID, TEST_ID_GRID_CONTAINER } from 'utils/constants';
import GridInteractionsHandler from './clickHandler/GridInteractionsHandler';
import GridAllDay from './GridAllDay';
import GridCalendarLinkContent from './GridCalendarLinkContent';
import { GridContactEventGhost } from './GridContactEventGhost';
import GridContent from './GridContent';
import GridCurrentTime from './GridCurrentTime';
import GridEventGhost from './GridEventGhost';
import GridLabels from './GridLabels';
import GridLines from './GridLines';
import GridScrollToCurrentTime from './GridScrollToCurrentTime';
import GridSpotifyTracks from './GridSpotifyTracks';
import GridTitles from './GridTitles';

export default React.memo(function GridWrapper() {
  const gridRef = useRef<HTMLDivElement | null>(null);
  const gridScrollRef = useRef<HTMLDivElement | null>(null);

  const calendarMode = useCalendarModeValue();

  const cursor = useCursorStyle();
  const days = useCalendarDays();

  // Mark grid as droppable zone for todos
  useTodosDroppable(gridRef, DroppableId.SCHEDULE);

  const timezonePreferences = useAtomValue(timezonePreferencesAtom);

  return (
    <div
      id="grid"
      ref={gridRef}
      role="presentation"
      data-testid={TEST_ID_GRID_CONTAINER}
      className="w-100 relative overflow-y-auto py-15"
      data-dd-action-name="click-grid-calendar"
      style={{ cursor }}
    >
      <GridScrollToCurrentTime
        scrollContainerRef={gridRef}
        scrollElementRef={gridScrollRef}
      />
      <GridTitles days={days} numberOfTimezones={timezonePreferences.length} />

      {calendarMode === 'default' && (
        <GridAllDay numberOfTimezones={timezonePreferences.length} />
      )}

      <div
        role="presentation"
        ref={gridScrollRef}
        data-testid={TEST_ID_GRID}
        className={`w-100 grid select-none grid-cols-calendar${timezonePreferences.length}Timezones grid-rows-calendar`}
      >
        <GridInteractionsHandler />
        <GridCurrentTime days={days} />
        <GridLines days={days} />
        <GridLabels />
        {calendarMode !== 'selecting-recurring-slots' && <GridContent />}
        <GridCalendarLinkContent calendarMode={calendarMode} days={days} />

        {calendarMode === 'default' && (
          <>
            <GridSpotifyTracks />
            <GridContactEventGhost />
            <GridEventGhost />
          </>
        )}
      </div>
    </div>
  );
});
